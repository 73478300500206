export default [
  // **********************************************************************************************************
  // **********************************************************************************************************
  //                                                Problem Type 3 INPUT
  // **********************************************************************************************************
  // **********************************************************************************************************
  // **********************************************************************************************************
  // Prob Input Field Gen Params
  // **********************************************************************************************************
  {
    keyword: 'caseNum',
    valueType: 2,
    fieldName: 'caseNum',
    default: 1,
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: 'finerSpacingBetween0and1',
    valueType: 1,
    fieldName: 'finerSpacingBetween0and1',
    default: false,
    initializer: undefined,
    type: 'input',
    label: 'Finer Spacing Between 0 and 1?',
    tooltip: 'Finer Spacing Between 0 and 1?',
    transform: (value) => {
      if(value === true || value === 'true') {
        return 1
      }
      return 0
    },
    inverseTransform: (value) => {
      if(value === 1) {
        return true
      }
      return false
    },
  },
  {
    keyword: 'tempDailyA',
    valueType: 2,
    fieldName: 'tempDailyA',
    default: 4.5,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'tempDailyC',
    valueType: 2,
    fieldName: 'tempDailyC',
    default: -0.5,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'tempDailyD',
    valueType: 2,
    fieldName: 'tempDailyD',
    default: -0.00002,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'tempWeeklyA',
    valueType: 2,
    fieldName: 'tempWeeklyA',
    default: -0.5,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'tempWeeklyC',
    valueType: 2,
    fieldName: 'tempWeeklyC',
    default: -120.0,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'tempWeeklyD',
    valueType: 2,
    fieldName: 'tempWeeklyD',
    default: -1.5,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'tempYearlyA',
    valueType: 2,
    fieldName: 'tempYearlyA',
    default: -20.0,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'tempYearlyC',
    valueType: 2,
    fieldName: 'tempYearlyC',
    default: 2250.0,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'tempYearlyD',
    valueType: 2,
    fieldName: 'tempYearlyD',
    default: -0.000002,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'tempConstantTrend',
    valueType: 2,
    fieldName: 'tempConstantTrend',
    default: 80.0,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'tempNoiseMu',
    valueType: 2,
    fieldName: 'tempNoiseMu',
    default: 0.05,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'tempNoiseSD',
    valueType: 2,
    fieldName: 'tempNoiseSD',
    default: 7.5,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'rhDailyA',
    valueType: 2,
    fieldName: 'rhDailyA',
    default: 0.6,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'rhDailyC',
    valueType: 2,
    fieldName: 'rhDailyC',
    default: -25.0,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'rhDailyD',
    valueType: 2,
    fieldName: 'rhDailyD',
    default: -0.0002,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'rhWeeklyA',
    valueType: 2,
    fieldName: 'rhWeeklyA',
    default: 20.0,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'rhWeeklyC',
    valueType: 2,
    fieldName: 'rhWeeklyC',
    default: 2500.0,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'rhWeeklyD',
    valueType: 2,
    fieldName: 'rhWeeklyD',
    default: 0.5,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'rhYearlyA',
    valueType: 2,
    fieldName: 'rhYearlyA',
    default: -3.5,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'rhYearlyC',
    valueType: 2,
    fieldName: 'rhYearlyC',
    default: 3.0,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'rhYearlyD',
    valueType: 2,
    fieldName: 'rhYearlyD',
    default: 0.00002,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'rhConstantTrend',
    valueType: 2,
    fieldName: 'rhConstantTrend',
    default: 50.0,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'rhNoiseMu',
    valueType: 2,
    fieldName: 'rhNoiseMu',
    default: -0.1,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'rhNoiseSD',
    valueType: 2,
    fieldName: 'rhNoiseSD',
    default: 20.0,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'dewPointDailyA',
    valueType: 2,
    fieldName: 'dewPointDailyA',
    default: -2.5,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'dewPointDailyC',
    valueType: 2,
    fieldName: 'dewPointDailyC',
    default: -1.25,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'dewPointDailyD',
    valueType: 2.0,
    fieldName: 'dewPointDailyD',
    default: 0.00004,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'dewPointWeeklyA',
    valueType: 2,
    fieldName: 'dewPointWeeklyA',
    default: 2.0,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'dewPointWeeklyC',
    valueType: 2,
    fieldName: 'dewPointWeeklyC',
    default: -25.0,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'dewPointWeeklyD',
    valueType: 2,
    fieldName: 'dewPointWeeklyD',
    default: 0.006,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'dewPointYearlyA',
    valueType: 2,
    fieldName: 'dewPointYearlyA',
    default: -9.0,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'dewPointYearlyC',
    valueType: 2,
    fieldName: 'dewPointYearlyC',
    default: 8.0,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'dewPointYearlyD',
    valueType: 2,
    fieldName: 'dewPointYearlyD',
    default: 0.000006,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'dewPointConstantTrend',
    valueType: 2,
    fieldName: 'dewPointConstantTrend',
    default: 55.0,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'dewPointNoiseMu',
    valueType: 2,
    fieldName: 'dewPointNoiseMu',
    default: -0.0025,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'dewPointNoiseSD',
    valueType: 2,
    fieldName: 'dewPointNoiseSD',
    default: 10.0,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'dewPointFitMethod',
    valueType: 0,
    fieldName: 'dewPointFitMethod',
    default: 'decompFit',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'rhFitMethod',
    valueType: 0,
    fieldName: 'rhFitMethod',
    default: 'decompFit',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'tempFitMethod',
    valueType: 0,
    fieldName: 'tempFitMethod',
    default: 'decompFit',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'dewPointInputType',
    valueType: 0,
    fieldName: 'dewPointInputType',
    default: 'constant',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'dewPointInputTypeProb',
    valueType: 0,
    fieldName: 'dewPointInputTypeProb',
    default: 'probabilistic',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'dewPointsMethod',
    valueType: 0,
    fieldName: 'dewPointsMethod',
    default: 'constant',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'dewPointsMethod',
    valueType: 0,
    fieldName: 'dewPointsMethod',
    default: 'Calculate',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'probabilisticInputFieldFlag',
    valueType: 1,
    fieldName: 'probabilisticInputFieldFlag',
    default: true,
    initializer: undefined,
    type: 'input',
    label: 'Flag to Enable Probabilistic Input Field',
    tooltip: 'Flag to Enable Probabilistic Input Field',
    transform: (value) => {
      if(value === true || value === 'true') {
        return 1
      }
      return 0
    },
    inverseTransform: (value) => {
      if(value === 1) {
        return true
      }
      return false
    },
  },
  {
    keyword: 'operatingTemperatureInputTypeProb',
    valueType: 0,
    fieldName: 'operatingTemperatureInputTypeProb',
    default: 'probabilistic',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'ambientTemperatureInputTypeProb',
    valueType: 0,
    fieldName: 'ambientTemperatureInputTypeProb',
    default: 'probabilistic',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'relativeHumidityInputTypeProb',
    valueType: 0,
    fieldName: 'relativeHumidityInputTypeProb',
    default: 'probabilistic',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  // **********************************************************************************************************
  // Constinuous Params
  // **********************************************************************************************************
  {
    keyword: 'internalDamageRateProb',
    fieldName: 'internalDamageRateProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'Internal Damage Rate',
      kind: 'nature',
      type: 'continuous',
      parents: null,
      states: {
        levels: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],
      },
      CPT: {
        neticaEquation: 'p(InternalDamageRate|)=NormalDist(InternalDamageRate, 10, 2.5)',
        sampleSize: 100,
      },
      distributionType: 'normal',
      median: 10,
      varianceType: 'standardDeviation',
      standardDeviation: 2.5,
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Internal Damage Rate',
  },
  {
    keyword: 'operatingTemperatureProb',
    fieldName: 'operatingTemperatureProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'temperature',
      kind: 'nature',
      type: 'continuous',
      parents: null,
      states: {
        levels: [-100, -80, -60, -40, -20, 0, 32, 45, 55, 65, 75, 85, 95, 105, 115, 125, 135, 145, 155, 165, 175, 185, 195, 205, 215, 225, 235, 245, 255, 265, 275, 350],
      },
      CPT: {
        neticaEquation: 'p(temperature|)=NormalDist(temperature, 70, 10)',
        sampleSize: 100,
      },
      distributionType: 'normal',
      median: 70,
      varianceType: 'standardDeviation',
      standardDeviation: 10,
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Temperature',
  },
  {
    keyword: 'dewPointAmProb',
    fieldName: 'dewPointAmProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'dewPointAm',
      kind: 'nature',
      type: 'continuous',
      parents: null,
      states: {
        levels: [-20, -10, 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120],
      },
      CPT: {
        neticaEquation: 'p(dewPointAm|)=NormalDist(dewPointAm, 60, 10)',
        sampleSize: 100,
      },
      distributionType: 'normal',
      median: 60,
      varianceType: 'standardDeviation',
      standardDeviation: 10,
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Dew Point Day',
  },
  {
    keyword: 'dewPointPmProb',
    fieldName: 'dewPointPmProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'dewPointPm',
      kind: 'nature',
      type: 'continuous',
      parents: null,
      states: {
        levels: [-20, -10, 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120],
      },
      CPT: {
        neticaEquation: 'p(dewPointPm|)=NormalDist(dewPointPm, 60, 10)',
        sampleSize: 100,
      },
      distributionType: 'normal',
      median: 60,
      varianceType: 'standardDeviation',
      standardDeviation: 10,
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Dew Point Night',
  },
  {
    keyword: 'temperatureAmProb',
    fieldName: 'temperatureAmProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'temperatureAm',
      kind: 'nature',
      type: 'continuous',
      parents: null,
      states: {
        levels: [0, 32, 45, 55, 65, 75, 85, 95, 105, 115, 125, 135, 145, 155, 165, 175, 185, 195, 205, 215, 225, 235, 245, 255, 265, 275, 350],
      },
      CPT: {
        neticaEquation: 'p(temperatureAm|)=NormalDist(temperatureAm, 70, 10)',
        sampleSize: 100,
      },
      distributionType: 'normal',
      median: 70,
      varianceType: 'standardDeviation',
      standardDeviation: 10,
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Temperature Day',
  },
  {
    keyword: 'temperaturePmProb',
    fieldName: 'temperaturePmProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'temperaturePm',
      kind: 'nature',
      type: 'continuous',
      parents: null,
      states: {
        levels: [0, 32, 45, 55, 65, 75, 85, 95, 105, 115, 125, 135, 145, 155, 165, 175, 185, 195, 205, 215, 225, 235, 245, 255, 265, 275, 350],
      },
      CPT: {
        neticaEquation: 'p(temperaturePm|)=NormalDist(temperaturePm, 70, 10)',
        sampleSize: 100,
      },
      distributionType: 'normal',
      median: 70,
      varianceType: 'standardDeviation',
      standardDeviation: 10,
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Temperature Night',
  },
  {
    keyword: 'relativeHumidityAmProb',
    fieldName: 'relativeHumidityAmProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'relativeHumidityAm',
      kind: 'nature',
      type: 'continuous',
      parents: null,
      states: {
        levels: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      },
      CPT: {
        neticaEquation: 'p(relativeHumidityAm|)=NormalDist(relativeHumidityAm, 50, 10)',
        sampleSize: 100,
      },
      distributionType: 'normal',
      median: 50,
      varianceType: 'standardDeviation',
      standardDeviation: 10,
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Relative Humidity Day',
  },
  {
    keyword: 'relativeHumidityPmProb',
    fieldName: 'relativeHumidityPmProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'relativeHumidityPm',
      kind: 'nature',
      type: 'continuous',
      parents: null,
      states: {
        levels: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      },
      CPT: {
        neticaEquation: 'p(relativeHumidityPm|)=NormalDist(relativeHumidityPm, 50, 10)',
        sampleSize: 100,
      },
      distributionType: 'normal',
      median: 50,
      varianceType: 'standardDeviation',
      standardDeviation: 10,
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Relative Humidity Night',
  },
  {
    keyword: 'nominalThicknessProb',
    fieldName: 'nominalThicknessProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'Starting Thickness',
      kind: 'nature',
      type: 'continuous',
      parents: null,
      states: {
        levels: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
      },
      CPT: {
        neticaEquation: 'p(StartingThickness|)=NormalDist(StartingThickness, 0.5, 0.1)',
        sampleSize: 100,
      },
      distributionType: 'normal',
      median: 0.5,
      varianceType: 'standardDeviation',
      standardDeviation: 0.1,
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Starting Thickness',
  },
  {
    keyword: 'failureThicknessProb',
    fieldName: 'failureThicknessProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'Failure Thickness',
      kind: 'nature',
      type: 'continuous',
      parents: null,
      states: {
        levels: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
      },
      CPT: {
        neticaEquation: 'p(FailureThickness|)=NormalDist(FailureThickness, 0.1, 0.025)',
        sampleSize: 100,
      },
      distributionType: 'normal',
      median: 0.1,
      varianceType: 'standardDeviation',
      standardDeviation: 0.025,
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Failure Thickness',
  },
  {
    keyword: 'measurementErrorProb',
    fieldName: 'measurementErrorProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'Measurement Error',
      kind: 'nature',
      type: 'continuous',
      parents: [],
      states: {
        levels: [0.0, 55.55556, 111.11111, 166.66667, 222.22222, 277.77778, 333.33333, 388.88889, 444.44444, 500.0],
      },
      CPT: {
        neticaEquation: 'p(MeasurementError|)=NormalDist(MeasurementError, 100, 25)',
        sampleSize: 100,
      },
      distributionType: 'normal',
      median: 100,
      varianceType: 'standardDeviation',
      standardDeviation: 25,
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'measurementErrorThicknessProb',
    fieldName: 'measurementErrorThicknessProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'Measurement Error',
      kind: 'nature',
      type: 'continuous',
      parents: [],
      states: {
        levels: [0.0, 55.55556, 111.11111, 166.66667, 222.22222, 277.77778, 333.33333, 388.88889, 444.44444, 500.0],
      },
      CPT: {
        neticaEquation: 'p(MeasurementError|)=NormalDist(MeasurementError, 100, 25)',
        sampleSize: 100,
      },
      distributionType: 'normal',
      median: 100,
      varianceType: 'standardDeviation',
      standardDeviation: 25,
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertCoatingFailureTimeProb',
    fieldName: 'expertCoatingFailureTimeProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'expertCoatingFailureTime',
      kind: 'nature',
      type: 'continuous',
      parents: [],
      states: {
        levels: [0, 5, 10, 15, 20, 25, 30, 35, 40],
      },
      CPT: {
        neticaEquation: 'p(expertCoatingFailureTime|)=NormalDist(expertCoatingFailureTime, 10, 2.5)',
        sampleSize: 100,
      },
      distributionType: 'normal',
      median: 10,
      varianceType: 'standardDeviation',
      standardDeviation: 2.5,
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertSealantFailureTimeMeanProb',
    fieldName: 'expertSealantFailureTimeMeanProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'expertSealantFailureTime',
      kind: 'nature',
      type: 'continuous',
      parents: [],
      states: {
        levels: [0, 5, 10, 15, 20, 25, 30, 35, 40],
      },
      CPT: {
        neticaEquation: 'p(expertSealantFailureTime|)=NormalDist(expertSealantFailureTime, 10, 2.5)',
        sampleSize: 100,
      },
      distributionType: 'normal',
      median: 10,
      varianceType: 'standardDeviation',
      standardDeviation: 2.5,
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertCorrosionFailureMeanProb',
    fieldName: 'expertCorrosionFailureMeanProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'expertCorrosionFailure',
      kind: 'nature',
      type: 'continuous',
      parents: [],
      states: {
        levels: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],
      },
      CPT: {
        neticaEquation: 'p(expertCorrosionFailure|)=NormalDist(expertCorrosionFailure, 10, 2.5)',
        sampleSize: 100,
      },
      distributionType: 'normal',
      median: 10,
      varianceType: 'standardDeviation',
      standardDeviation: 2.5,
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertJacketingDesignFailureMeanProb',
    fieldName: 'expertJacketingDesignFailureMeanProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'expertDesignFailure',
      kind: 'nature',
      type: 'continuous',
      parents: [],
      states: {
        levels: [0, 5, 10, 15, 20, 25, 30, 35, 40],
      },
      CPT: {
        neticaEquation: 'p(expertDesignFailure|)=NormalDist(expertDesignFailure, 10, 2.5)',
        sampleSize: 100,
      },
      distributionType: 'normal',
      median: 10,
      varianceType: 'standardDeviation',
      standardDeviation: 2.5,
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertExternalExposureFractionMeanProb',
    fieldName: 'expertExternalExposureFractionMeanProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'expertExternalExposureFraction',
      kind: 'nature',
      type: 'continuous',
      parents: [],
      states: {
        levels: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      },
      CPT: {
        neticaEquation: 'p(expertExternalExposureFraction|)=NormalDist(expertExternalExposureFraction, 10, 2.5)',
        sampleSize: 100,
      },
      distributionType: 'normal',
      median: 10,
      varianceType: 'standardDeviation',
      standardDeviation: 2.5,
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertWetCorrosionRateProb',
    fieldName: 'expertWetCorrosionRateProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'expertWetCorrosionRate',
      kind: 'nature',
      type: 'continuous',
      parents: [],
      states: {
        levels: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],
      },
      CPT: {
        neticaEquation: 'p(expertWetCorrosionRate|)=NormalDist(expertWetCorrosionRate, 10, 2.5)',
        sampleSize: 100,
      },
      distributionType: 'normal',
      median: 10,
      varianceType: 'standardDeviation',
      standardDeviation: 2.5,
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertEffectiveCorrosionRateMeanProb',
    fieldName: 'expertEffectiveCorrosionRateMeanProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'expertEffectiveCorrosionRate',
      kind: 'nature',
      type: 'continuous',
      parents: [],
      states: {
        levels: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],
      },
      CPT: {
        neticaEquation: 'p(expertEffectiveCorrosionRate|)=NormalDist(expertEffectiveCorrosionRate, 10, 2.5)',
        sampleSize: 100,
      },
      distributionType: 'normal',
      median: 10,
      varianceType: 'standardDeviation',
      standardDeviation: 2.5,
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  // **********************************************************************************************************
  // Discrete Params
  // **********************************************************************************************************
  {
    keyword: 'expertAnnualProbabilityMissingJacketingProb',
    fieldName: 'expertAnnualProbabilityMissingJacketingProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'Expert Annual Probability Missing Jacketing',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        states: ['Yes', 'No'],
      },
      CPT: {
        table: [0.25, 0.75],
      },
      evidence: [0.5, 0.5],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'externalEnvironment1Prob',
    fieldName: 'externalEnvironment1Prob',
    valueType: 0,
    default: JSON.stringify({
      name: 'externalEnvironment',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [30, 20, 15, 15, 10, 5, 5],
        states: ['Water', 'Non-Saline Cooling Tower', 'Low % Marine Salt', 'Low % Acid Rain', 'Saline Cooling Tower', 'High % Marine Salt', 'High % Acid Rain'],
      },
      CPT: {
        table: [0.6, 0.1, 0.05, 0.05, 0.1, 0.05, 0.05],
      },
      evidence: [0.14278, 0.14278, 0.14278, 0.14278, 0.14278, 0.14278, 0.14278],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'External Environment',
  },
  {
    keyword: 'annualRainfall2Prob',
    fieldName: 'annualRainfall2Prob',
    valueType: 0,
    default: JSON.stringify({
      name: 'annualRainfall',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [0, 5, 10, 20, 30, 40],
        states: ['None', 'Indirect', '<15in', '15-30in', '30-60in', '>60in'],
      },
      CPT: {
        table: [0.03, 0.07, 0.2, 0.4, 0.2, 0.1],
      },
      evidence: [0.16667, 0.16667, 0.16667, 0.16667, 0.16667, 0.16667],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Annual Rainfall',
  },
  {
    keyword: 'windSpeedFraction1Prob',
    fieldName: 'windSpeedFraction1Prob',
    valueType: 0,
    default: JSON.stringify({
      name: 'windSpeedFraction1',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [0, 30, 60, 100],
        states: ['0 to 30', '30 to 60', '60 to 100'],
      },
      CPT: {
        table: [0.8, 0.15, 0.05],
      },
      evidence: [0.25, 0.25, 0.25, 0.25],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Wind Speed Fraction',
  },
  {
    keyword: 'elevationProb',
    fieldName: 'elevationProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'elevation',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [0, 50, 100, 150, 200, 250, 300],
        states: ['0 to 50', '50 to 100', '100 to 150', '150 to 200', '200 to 250', '250 to 300'],
      },
      CPT: {
        table: [0.5, 0.3, 0.1, 0.05, 0.03, 0.02],
      },
      evidence: [0.16667, 0.16667, 0.16667, 0.16667, 0.16667, 0.16667],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Elevation',
  },
  {
    keyword: 'internalPhProb',
    fieldName: 'internalPhProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'internalPh',
      kind: 'nature',
      type: 'discrete',
      parents: ['calcSilicate'],
      states: {
        states: ['Alkaline', 'Neutral', 'Acidic'],
      },
      CPT: {
        table: [],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Internal pH',
  },
  {
    keyword: 'steamVentExposureProb',
    fieldName: 'steamVentExposureProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'steamVentExposure',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [50, 0],
        states: ['Yes', 'No'],
      },
      CPT: {
        table: [0.01, 0.99],
      },
      evidence: [0.5, 0.5],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Steam Vent Exposure',
  },
  {
    keyword: 'otherDirectExposureProb',
    fieldName: 'otherDirectExposureProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'otherDirectExposure',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [50, 0],
        states: ['Yes', 'No'],
      },
      CPT: {
        table: [0.02, 0.98],
      },
      evidence: [0.5, 0.5],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Other Direct Exposure',
  },
  {
    keyword: 'uvExposureProb',
    fieldName: 'uvExposureProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'uvExposure',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [1.25, 1.0, 0.8],
        states: ['Low (0-2)', 'Moderate (3-8)', 'High (>8)'],
      },
      CPT: {
        table: [0.2, 0.6, 0.2],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'UV Exposure',
  },
  {
    keyword: 'modelConfidenceForJacketingDesignFailureTimeProb',
    fieldName: 'modelConfidenceForJacketingDesignFailureTimeProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'modelConfidence',
      title: 'Jacket Design Failure Time Model Confidence',
      units: 'yrs',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [5, 2, 0.5],
        states: ['Low', 'Moderate', 'High'],
      },
      CPT: {
        table: [0.33, 0.33, 0.33],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Model Confidence',
  },
  {
    keyword: 'modelConfidenceForCorrosionFailureTimeProb',
    fieldName: 'modelConfidenceForCorrosionFailureTimeProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'modelConfidence',
      title: 'Jacket Corrosion Failure Time Model Confidence',
      units: 'yrs',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [5, 2, 0.5],
        states: ['Low', 'Moderate', 'High'],
      },
      CPT: {
        table: [0.33, 0.33, 0.33],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Model Confidence',
  },
  {
    keyword: 'modelConfidenceForUpdatedMissingJacketFailureTimeProb',
    fieldName: 'modelConfidenceForUpdatedMissingJacketFailureTimeProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'modelConfidence',
      titl: 'Jacket Missing First Failure Time Model Confidence',
      units: 'yrs',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [5, 2, 0.5],
        states: ['Low', 'Moderate', 'High'],
      },
      CPT: {
        table: [0.33, 0.33, 0.33],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Model Confidence',
  },
  {
    keyword: 'modelConfidenceForSealantFailureTimeProb',
    fieldName: 'modelConfidenceForSealantFailureTimeProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'modelConfidence',
      title: 'Sealant Failure Time Model Confidence',
      units: 'yrs',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [5, 2, 0.5],
        states: ['Low', 'Moderate', 'High'],
      },
      CPT: {
        table: [0.33, 0.33, 0.33],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Model Confidence',
  },
  {
    keyword: 'dewPointYearlyFractionConfidenceProb',
    fieldName: 'dewPointYearlyFractionConfidenceProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'Dew Point Yearly Fraction Confidence',
      title: 'Dew Point Model Yearly Fraction Confidence',
      units: '%',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [10, 5, 1],
        states: ['Low', 'Moderate', 'High'],
      },
      CPT: {
        table: [0.33, 0.33, 0.33],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Model Confidence',
  },
  {
    keyword: 'modelConfidenceForCoatingDesignLifeProb',
    fieldName: 'modelConfidenceForCoatingDesignLifeProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'modelConfidence',
      title: 'Coating Design Life Model Confidence',
      units: 'yrs',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [5, 2, 0.5],
        states: ['Low', 'Moderate', 'High'],
      },
      CPT: {
        table: [0.33, 0.33, 0.33],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Model Confidence',
  },
  {
    keyword: 'modelConfidenceForEffectiveCorrosionRateProb',
    fieldName: 'modelConfidenceForEffectiveCorrosionRateProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'modelConfidence',
      title: 'Max Effective Corrosion Rate Model Confidence',
      units: 'mpy',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [0.1, 0.05, 0.1],
        states: ['Low', 'Moderate', 'High'],
      },
      CPT: {
        table: [0.33, 0.33, 0.33],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Model Confidence',
  },
  {
    keyword: 'modelConfidenceWetCorrosionRateProb',
    fieldName: 'modelConfidenceWetCorrosionRateProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'modelConfidence',
      title: 'Wet Corrosion Rate Model Confidence',
      units: 'mpy',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [0.3, 0.1, 0.03],
        states: ['Low', 'Moderate', 'High'],
      },
      CPT: {
        table: [0.33, 0.33, 0.33],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Model Confidence',
  },
  {
    keyword: 'internalExposureFractionConfidenceProb',
    fieldName: 'internalExposureFractionConfidenceProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'modelConfidence',
      title: 'Internal Exposure Fraction Flux Rate Model Confidences',
      units: '',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [0.2, 0.1, 0.05],
        states: ['Low', 'Moderate', 'High'],
      },
      CPT: {
        table: [0.33, 0.33, 0.33],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Model Confidence',
  },
  {
    keyword: 'modelConfidenceForExternalExposureFractionProb',
    fieldName: 'modelConfidenceForExternalExposureFractionProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'modelConfidence',
      title: 'External Exposure Fraction Model Confidence',
      units: '%',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [10, 5, 1],
        states: ['Low', 'Moderate', 'High'],
      },
      CPT: {
        table: [0.33, 0.33, 0.33],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Model Confidence',
  },
  {
    keyword: 'attachmentsProb',
    fieldName: 'attachmentsProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'numberOfAttachments',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        states: ['Few', 'Some', 'Many'],
      },
      CPT: {
        table: [0.2, 0.6, 0.2],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Number of Attachments',
  },
  {
    keyword: 'pipingComplexityProb',
    fieldName: 'pipingComplexityProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'pipingComplexity',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        states: ['Simple', 'Average', 'Complex'],
      },
      CPT: {
        table: [0.2, 0.6, 0.2],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Piping Complexity',
  },
  {
    keyword: 'accessibilityProb',
    fieldName: 'accessibilityProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'accessibility',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        states: ['Accessible', 'Congested', 'Elevated', 'Congested and Elevated'],
      },
      CPT: {
        table: [0.5, 0.2, 0.2, 0.1],
      },
      evidence: [0.25, 0.25, 0.25, 0.25],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Accessibility',
  },
  {
    keyword: 'steamTracingVarianceProb',
    fieldName: 'steamTracingVarianceProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'steamTracingVariance',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [5, 7, 10],
        states: ['Low', 'Moderate', 'High'],
      },
      CPT: {
        table: [0.25, 0.5, 0.25],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Steam Tracing Variance',
  },
  {
    keyword: 'steamTracingProb',
    fieldName: 'steamTracingProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'steamTracing',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [0, 50],
        states: ['No', 'Yes'],
      },
      CPT: {
        table: [0.9, 0.1],
      },
      evidence: [0.5, 0.5],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Steam Tracing',
  },
  {
    keyword: 'steamTracingQualityProb',
    fieldName: 'steamTracingQualityProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'steamTracingQuality',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [1.3, 1, 0.75],
        states: ['Poor', 'Average', 'Quality'],
      },
      CPT: {
        table: [0.25, 0.5, 0.25],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Steam Tracing Quality',
  },
  // **********************************************************************************************************
  {
    keyword: 'vteCorrosionSeverityProb',
    fieldName: 'vteCorrosionSeverityProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'VTE Corrosion Severity',
      kind: 'nature',
      type: 'discrete',
      parents: ['Severity at Last VTE Inspection'],
      states: {
        states: ['None', 'Minimal', 'Minor', 'Major', 'Severe'],
      },
      CPT: {
        table: [
          [0.6, 0.3, 0.07, 0.02, 0.01],
          [0.2, 0.4, 0.2, 0.15, 0.05],
          [0.1, 0.2, 0.4, 0.2, 0.1],
          [0.05, 0.15, 0.2, 0.4, 0.2],
          [0.01, 0.02, 0.07, 0.3, 0.6],
        ],
      },
      evidence: [0.2, 0.2, 0.2, 0.2, 0.2],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'coatingConditionVTEProb',
    fieldName: 'coatingConditionVTEProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'Coating Condition at Last VTE Inspection',
      kind: 'nature',
      type: 'discrete',
      parents: ['Coating Failure at VTE'],
      states: {
        states: ['None', 'Minimal', 'Minor', 'Major', 'Severe'],
      },
      CPT: {
        table: [
          [0.03, 0.07, 0.2, 0.3, 0.4],
          [0.4, 0.3, 0.2, 0.07, 0.03]
        ],
      },
      evidence: [0.2, 0.2, 0.2, 0.2, 0.2],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'sealantFailureProb',
    fieldName: 'sealantFailureProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'Jacketing Sealant Condition',
      kind: 'nature',
      type: 'discrete',
      parents: ['Jacketing Sealant Failure'],
      states: {
        states: ['None', 'Sealed', 'Cracks no Gaps', 'Open Gaps'],
      },
      CPT: {
        table: [
          [0.05, 0.15, 0.3, 0.5],
          [0.4, 0.4, 0.15, 0.05]
        ],
      },
      evidence: [0.25, 0.25, 0.25, 0.25],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'jacketConditionProb',
    fieldName: 'jacketConditionProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'Jacket Condition at Last VTE Inspection',
      kind: 'nature',
      type: 'discrete',
      parents: ['Jacketing Failure at VTE'],
      states: {
        states: ['None', 'Minimal', 'Minor', 'Major', 'Severe'],
      },
      CPT: {
        table: [
          [0.03, 0.07, 0.2, 0.3, 0.4],
          [0.4, 0.3, 0.2, 0.07, 0.03]
        ],
      },
      evidence: [0.2, 0.2, 0.2, 0.2, 0.2],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'jacketConditionGeneralProb',
    fieldName: 'jacketConditionGeneralProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'Jacket General Condition at Last VTE Inspection',
      kind: 'nature',
      type: 'discrete',
      parents: ['Jacketing Failure at VTE'],
      states: {
        states: ['None', 'Minimal', 'Minor', 'Major', 'Severe'],
      },
      CPT: {
        table: [
          [0.03, 0.07, 0.2, 0.3, 0.4],
          [0.4, 0.3, 0.2, 0.07, 0.03]
        ],
      },
      evidence: [0.2, 0.2, 0.2, 0.2, 0.2],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'missingAtVTEProb',
    fieldName: 'missingAtVTEProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'Jacketing Missing at VTE',
      kind: 'nature',
      type: 'discrete',
      parents: ['Jacketing Missing Failure'],
      states: {
        states: ['No', 'Yes'],
      },
      CPT: {
        table: [
          [0.1, 0.9],
          [0.9, 0.1]
        ],
      },
      evidence: [0.5, 0.5],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'vtiCorrosionSeverityProb',
    fieldName: 'vtiCorrosionSeverityProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'VTI Corrosion Severity',
      kind: 'nature',
      type: 'discrete',
      parents: ['Severity at Last VTI Inspection'],
      states: {
        states: ['None', 'Minimal', 'Minor', 'Major', 'Severe'],
      },
      CPT: {
        table: [
          [0.6, 0.3, 0.07, 0.02, 0.01],
          [0.2, 0.4, 0.2, 0.15, 0.05],
          [0.1, 0.2, 0.4, 0.2, 0.1],
          [0.05, 0.15, 0.2, 0.4, 0.2],
          [0.01, 0.02, 0.07, 0.3, 0.6],
        ],
      },
      evidence: [0.2, 0.2, 0.2, 0.2, 0.2],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'coatingConditionVTIProb',
    fieldName: 'coatingConditionVTIProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'Coating Condition at Last VTI Inspection',
      kind: 'nature',
      type: 'discrete',
      parents: ['Coating Failure at VTI'],
      states: {
        states: ['None', 'Minimal', 'Minor', 'Major', 'Severe'],
      },
      CPT: {
        table: [
          [0.03, 0.07, 0.2, 0.3, 0.4],
          [0.4, 0.3, 0.2, 0.07, 0.03]
        ],
      },
      evidence: [0.2, 0.2, 0.2, 0.2, 0.2],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'insulationWetAtVTIProb',
    fieldName: 'insulationWetAtVTIProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'Jacketing Failure at VTI',
      kind: 'nature',
      type: 'discrete',
      parents: ['Jacketing Failure Intrusive'],
      states: {
        states: ['Yes', 'No'],
      },
      CPT: {
        table: [
          [0.9, 0.1],
          [0.1, 0.9]
        ],
      },
      evidence: [0.5, 0.5],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'ndeCorrosionSeverityProb',
    fieldName: 'ndeCorrosionSeverityProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'NDE Corrosion Severity',
      kind: 'nature',
      type: 'discrete',
      parents: ['Severity at Last NDE Inspection'],
      states: {
        states: ['None', 'Minimal', 'Minor', 'Major', 'Severe'],
      },
      CPT: {
        table: [
          [0.6, 0.3, 0.07, 0.02, 0.01],
          [0.2, 0.4, 0.2, 0.15, 0.05],
          [0.1, 0.2, 0.4, 0.2, 0.1],
          [0.05, 0.15, 0.2, 0.4, 0.2],
          [0.01, 0.02, 0.07, 0.3, 0.6],
        ],
      },
      evidence: [0.2, 0.2, 0.2, 0.2, 0.2],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'coatingConditionNDEProb',
    fieldName: 'coatingConditionNDEProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'Coating Condition at Last NDE Inspection',
      kind: 'nature',
      type: 'discrete',
      parents: ['Coating Failure at NDE'],
      states: {
        states: ['None', 'Minimal', 'Minor', 'Major', 'Severe'],
      },
      CPT: {
        table: [
          [0.03, 0.07, 0.2, 0.3, 0.4],
          [0.4, 0.3, 0.2, 0.07, 0.03]
        ],
      },
      evidence: [0.2, 0.2, 0.2, 0.2, 0.2],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'coatingMaterialProb',
    fieldName: 'coatingMaterialProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'coatingMaterial',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [0, 0, 5, 5, 2, 10, 15, 15, 5, 10, 15, 5, 20, 2, 5],
        states: ['None (0 yrs)', 'Primer', 'Hot Dip Galvanizing', 'Zinc Silicate w/ Top Seal', '2 Layer with Zinc Rich Primer as 1st Layer', '3 Layer with Zinc Rich Primer as 1st Layer', 'Polyster Based Coating', '2 Component Epoxy', '3 Layer on Epoxy Primer', '2 Layer Epoxy', '2 Layer Phenolic/Novolac', 'Fusion Bonded Epoxy', 'Thermal Spray Aluminum w/ Top Seal', 'Air Dried or Modified Silicone', 'Inorganic Polymer'],
      },
      CPT: {
        table: [0.07143, 0.07143, 0.07143, 0.07143, 0.07143, 0.07143, 0.07143, 0.07143, 0.07143, 0.07143, 0.07143, 0.07143, 0.07143, 0.07143],
      },
      evidence: [0.07143, 0.07143, 0.07143, 0.07143, 0.07143, 0.07143, 0.07143, 0.07143, 0.07143, 0.07143, 0.07143, 0.07143, 0.07143, 0.07143],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'coatingInstallQualityProb',
    fieldName: 'coatingInstallQualityProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'installQuality',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [0.8, 1, 1.25],
        states: ['Low', 'Moderate', 'High'],
      },
      CPT: {
        table: [0.2, 0.6, 0.2],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'coatingInstalledInTheFieldProb',
    fieldName: 'coatingInstalledInTheFieldProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'coatingAppliedInField',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [1.25, 1.0, 0.8],
        states: ['No', 'Weld Joints Only', 'Yes'],
      },
      CPT: {
        table: [0.2, 0.3, 0.5],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'coatingPostInstallQAQCProb',
    fieldName: 'coatingPostInstallQAQCProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'postInstallQaQc',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [1.25, 0.8],
        states: ['Yes', 'No'],
      },
      CPT: {
        table: [0.2, 0.8],
      },
      evidence: [0.5, 0.5],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'coatingDesignedForCuiProb',
    fieldName: 'coatingDesignedForCuiProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'coatingForCuiService',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        states: ['Yes', 'No'],
      },
      CPT: {
        table: [0.2, 0.8],
      },
      evidence: [0.5, 0.5],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'insulationTypeProb',
    fieldName: 'insulationTypeProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'insulationType',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        states: ['Calcium Silicate High Chloride', 'Calcium Silicate Low Chloride', 'Mineral Wool', 'Perlite', 'Foam Glass', 'Expanded Perlite', 'Modern Aerogels'],
      },
      CPT: {
        table: [0.2, 0.2, 0.2, 0.2, 0.08, 0.08, 0.04],
      },
      evidence: [0.14286, 0.14286, 0.14286, 0.14286, 0.14286, 0.14286, 0.14286],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'properDrainageProb',
    fieldName: 'properDrainageProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'properDrainage',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [1.25, 0.75],
        states: ['Yes', 'No'],
      },
      CPT: {
        table: [0.2, 0.8],
      },
      evidence: [0.5, 0.5],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'moistureAccumulationSitesProb',
    fieldName: 'moistureAccumulationSitesProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'moistureAccumulationSites',
      kind: 'nature',
      type: 'discrete',
      parents: ['numberOfAttachments'],
      states: {
        levels: [1.25, 0.75],
        states: ['Yes', 'No'],
      },
      CPT: {
        table: [
          [
            0.4,
            0.6
          ],
          [
            0.6,
            0.4
          ],
          [
            0.8,
            0.2
          ]
        ],
      },
      evidence: [0.5, 0.5],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'lowPointWaterTrapProb',
    fieldName: 'lowPointWaterTrapProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'lowSpots',
      kind: 'nature',
      type: 'discrete',
      parents: ['componentType'],
      states: {
        levels: [1.25, 0.75],
        states: ['Yes', 'No'],
      },
      CPT: {
        table: [0.01,
          0.99,
          0.7,
          0.3,
          0.7,
          0.3,
          0.05,
          0.95,
          0.7,
          0.35,
          0.35,
          0.35,
          0.3,
          0.7,
          0.3,
          0.7,
          0.3,
          0.5,
          0.5,
          0.5,
          0.5,
          0.65,
          0.65,
          0.65],
      },
      evidence: [0.5, 0.5],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'protrusionsCoveredProb',
    fieldName: 'protrusionsCoveredProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'protrusionsCovered',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        states: ['Yes', 'No'],
      },
      CPT: {
        table: [0.2, 0.8],
      },
      evidence: [0.5, 0.5],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'horizontalLapJointsProb',
    fieldName: 'horizontalLapJointsProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'horizontalLapJoints',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        states: ['Poor Placement and Overlap', 'Poor Placement Only', 'Poor Overlap Only', 'Proper Placement and Overlap'],
      },
      CPT: {
        table: [0.2, 0.2, 0.4, 0.2],
      },
      evidence: [0.25, 0.25, 0.25, 0.25],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'verticalTelescopingProb',
    fieldName: 'verticalTelescopingProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'verticalTelescoping',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        states: ['Up', 'None', 'Down'],
      },
      CPT: {
        table: [0.2, 0.7, 0.1],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'endCapTypeProb',
    fieldName: 'endCapTypeProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'endCapQuality',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        states: ['Open', 'Poor Design', 'Quality Design'],
      },
      CPT: {
        table: [0.1, 0.6, 0.3],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'jacketingMaterialProb',
    fieldName: 'jacketingMaterialProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'jacketType',
      kind: 'nature',
      type: 'discrete',
      parents: ['jacketMetallic', 'insulationType'],
      states: {
        states: ['Galvanized Steel', 'Aluminum', 'Stainless Steel', 'Non-Metallic'],
      },
      CPT: {
        table: [
          [
            0.6,
            0.05,
            0.35,
            0
          ],
          [
            0.2,
            0.6,
            0.2,
            0
          ],
          [
            0,
            0,
            0,
            1
          ],
          [
            0,
            0,
            0,
            1
          ]
        ],
      },
      evidence: [0.25, 0.25, 0.25, 0.25],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'jacketMetallicProb',
    fieldName: 'jacketMetallicProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'jacketMetallic',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        states: ['Yes', 'No'],
      },
      CPT: {
        table: [0.9, 0.1],
      },
      evidence: [0.5, 0.5],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'jacketInstallQualityProb',
    fieldName: 'jacketInstallQualityProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'jacketingInstallQuality',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        states: ['Low', 'Moderate', 'High'],
      },
      CPT: {
        table: [0.5, 0.3, 0.2],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'jacketPostInstallQAQCProb',
    fieldName: 'jacketPostInstallQAQCProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'jacketingPostInstallQaQc',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        states: ['Yes', 'No'],
      },
      CPT: {
        table: [0.2, 0.8],
      },
      evidence: [0.5, 0.5],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'sealantAmountProb',
    fieldName: 'sealantAmountProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'sealantAmount',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [10, 5, 1],
        states: ['Few', 'Some', 'Many'],
      },
      CPT: {
        table: [0.2, 0.6, 0.2],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'highTempSealantProb',
    fieldName: 'highTempSealantProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'highTempSealant',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [1.5, 1.0],
        states: ['Yes', 'No'],
      },
      CPT: {
        table: [0.2, 0.8],
      },
      evidence: [0.5, 0.5],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'sealantPostInstallQAQCProb',
    fieldName: 'sealantPostInstallQAQCProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'sealantPostInstallQaQc',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [1.25, 1.0],
        states: ['Yes', 'No'],
      },
      CPT: {
        table: [0.2, 0.8],
      },
      evidence: [0.5, 0.5],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },

  {
    keyword: 'sealantInstallQualityProb',
    fieldName: 'sealantInstallQualityProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'sealantInstallQuality',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [1.5, 1.0, 0.5],
        states: ['Poor', 'Average', 'Quality'],
      },
      CPT: {
        table: [0.4, 0.4, 0.2],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertExternalExposureFractionConfidenceProb',
    fieldName: 'expertExternalExposureFractionConfidenceProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'expertConfidence',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [10, 5, 1],
        states: ['Low', 'Moderate', 'High'],
      },
      CPT: {
        table: [0.33, 0.33, 0.33],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Expert Confidence',
  },
  {
    keyword: 'expertWetCorrosionRateConfidenceProb',
    fieldName: 'expertWetCorrosionRateConfidenceProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'expertConfidence',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [5, 2, 0.5],
        states: ['Low', 'Moderate', 'High'],
      },
      CPT: {
        table: [0.6, 0.3, 0.1],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Expert Confidence',
  },
  {
    keyword: 'expertEffectiveCorrosionRateConfidenceProb',
    fieldName: 'expertEffectiveCorrosionRateConfidenceProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'expertConfidence',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [5, 2, 0.5],
        states: ['Low', 'Moderate', 'High'],
      },
      CPT: {
        table: [0.6, 0.3, 0.1],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Expert Confidence',
  },
  {
    keyword: 'expertCoatingFailureTimeConfidenceProb',
    fieldName: 'expertCoatingFailureTimeConfidenceProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'expertConfidence',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [5, 2, 0.5],
        states: ['Low', 'Moderate', 'High'],
      },
      CPT: {
        table: [0.33, 0.33, 0.33],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Expert Confidence',
  },
  {
    keyword: 'expertSealantFailureTimeConfidenceProb',
    fieldName: 'expertSealantFailureTimeConfidenceProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'expertConfidence',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [5, 2, 0.5],
        states: ['Low', 'Moderate', 'High'],
      },
      CPT: {
        table: [0.33, 0.33, 0.33],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Expert Confidence',
  },
  {
    keyword: 'expertCorrosionFailureConfidenceProb',
    fieldName: 'expertCorrosionFailureConfidenceProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'expertConfidence',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [5, 2, 0.5],
        states: ['Low', 'Moderate', 'High'],
      },
      CPT: {
        table: [0.33, 0.33, 0.33],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Expert Confidence',
  },
  {
    keyword: 'expertJacketingDesignFailureTimeConfidenceProb',
    fieldName: 'expertJacketingDesignFailureTimeConfidenceProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'expertConfidence',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        levels: [5, 2, 0.5],
        states: ['Low', 'Moderate', 'High'],
      },
      CPT: {
        table: [0.33, 0.33, 0.33],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Expert Confidence',
  },
  {
    keyword: 'expertMissingJacketingConfidenceProb',
    fieldName: 'expertMissingJacketingConfidenceProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'Expert Missing Jacketing Confidence',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        states: ['Low', 'Moderate', 'High'],
      },
      CPT: {
        table: [0.33, 0.33, 0.33],
      },
      evidence: [0.33, 0.33, 0.33],
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Expert Confidence',
  },
  // **********************************************************************************************************
  // **********************************************************************************************************
  //                                                Problem Type 3 INPUT
  // **********************************************************************************************************
  // **********************************************************************************************************
  {
    keyword: 'jacketMetallic',
    valueType: 0,
    fieldName: 'jacketMetallic',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'relativeHumidityCV',
    valueType: 2,
    fieldName: 'relativeHumidityCV',
    min: 0,
    default: 0.1,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'temperatureWinterCV',
    valueType: 2,
    fieldName: 'temperatureWinterCV',
    min: 0,
    default: 0.1,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'temperatureSpringCV',
    valueType: 2,
    fieldName: 'temperatureSpringCV',
    min: 0,
    default: 0.1,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'temperatureFallCV',
    valueType: 2,
    fieldName: 'temperatureFallCV',
    min: 0,
    default: 0.1,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'temperatureSummerCV',
    valueType: 2,
    fieldName: 'temperatureSummerCV',
    min: 0,
    default: 0.1,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'relativeHumiditySD',
    valueType: 2,
    fieldName: 'relativeHumiditySD',
    default: { USCustomary: 1.0, Metric: 1.0 },
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'temperatureWinterSD',
    valueType: 2,
    fieldName: 'temperatureWinterSD',
    default: { USCustomary: 1.0, Metric: 1 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'temperatureSpringSD',
    valueType: 2,
    fieldName: 'temperatureSpringSD',
    default: { USCustomary: 1.0, Metric: 1 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'temperatureFallSD',
    valueType: 2,
    fieldName: 'temperatureFallSD',
    default: { USCustomary: 1.0, Metric: 1 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'temperatureSummerSD',
    valueType: 2,
    fieldName: 'temperatureSummerSD',
    default: { USCustomary: 1.0, Metric: 1 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'dewPointAmSD',
    valueType: 2,
    fieldName: 'dewPointAmSD',
    default: { USCustomary: 1.0, Metric: 1 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'dewPointPmSD',
    valueType: 2,
    fieldName: 'dewPointPmSD',
    default: { USCustomary: 1.0, Metric: 1 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'dewPointAmCV',
    valueType: 2,
    fieldName: 'dewPointAmCV',
    min: 0,
    default: 0.1,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'dewPointPmCV',
    valueType: 2,
    fieldName: 'dewPointPmCV',
    min: 0,
    default: 0.1,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'temperatureAmSD',
    valueType: 2,
    fieldName: 'temperatureAmSD',
    default: { USCustomary: 1.0, Metric: 1 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'temperaturePmSD',
    valueType: 2,
    fieldName: 'temperaturePmSD',
    default: { USCustomary: 1.0, Metric: 1 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'temperatureAmCV',
    valueType: 2,
    fieldName: 'temperatureAmCV',
    min: 0,
    default: 0.1,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'temperaturePmCV',
    valueType: 2,
    fieldName: 'temperaturePmCV',
    min: 0,
    default: 0.1,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'relativeHumidityAmSD',
    valueType: 2,
    fieldName: 'relativeHumidityAmSD',
    default: { USCustomary: 1.0, Metric: 1.0 },
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'relativeHumidityPmSD',
    valueType: 2,
    fieldName: 'relativeHumidityPmSD',
    default: { USCustomary: 1.0, Metric: 1.0 },
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'relativeHumidityAmCV',
    valueType: 2,
    fieldName: 'relativeHumidityAmCV',
    min: 0,
    default: 0.1,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'relativeHumidityPmCV',
    valueType: 2,
    fieldName: 'relativeHumidityPmCV',
    min: 0,
    default: 0.1,
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'condensationParametersVarianceTypeDp',
    valueType: 0,
    default: 'Confidence',
    fieldName: 'condensationParametersVarianceTypeDp',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'condensationParametersVarianceTypeTemp',
    valueType: 0,
    default: 'Confidence',
    fieldName: 'condensationParametersVarianceTypeTemp',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'condensationParametersVarianceTypeRh',
    valueType: 0,
    default: 'Confidence',
    fieldName: 'condensationParametersVarianceTypeRh',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'dewPointAmConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'dewPointAmConfidence',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'dewPointPmConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'dewPointPmConfidence',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'temperatureAmConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'temperatureAmConfidence',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'temperaturePmConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'temperaturePmConfidence',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'relativeHumidityAmConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'relativeHumidityAmConfidence',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'relativeHumidityPmConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'relativeHumidityPmConfidence',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'temperatureSummerConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'temperatureSummerConfidence',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'temperatureFallConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'temperatureFallConfidence',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'temperatureWinterConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'temperatureWinterConfidence',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'temperatureSpringConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'temperatureSpringConfidence',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'relativeHumidityConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'relativeHumidityConfidence',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'ramOverheadValue',
    valueType: 0,
    default: '4',
    fieldName: 'ramOverheadValue',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'sealantInstallQuality',
    valueType: 0,
    default: 'unknown',
    fieldName: 'sealantInstallQuality',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'highTempSealant',
    valueType: 0,
    default: 'unknown',
    fieldName: 'highTempSealant',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'sealantPostInstallQAQC',
    valueType: 0,
    default: 'unknown',
    fieldName: 'sealantPostInstallQAQC',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'vaporBarrierPostInstallQaQc',
    valueType: 0,
    default: 'unknown',
    fieldName: 'vaporBarrierPostInstallQaQc',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'vaporBarrierInstallQuality',
    valueType: 0,
    default: 'unknown',
    fieldName: 'vaporBarrierInstallQuality',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'steamTracingAge',
    valueType: 2,
    fieldName: 'steamTracingAge',
    min: 0,
    default: 5,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'steamTracingVariance',
    valueType: 0,
    default: 'unknown',
    fieldName: 'steamTracingVariance',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'steamTracingQuality',
    valueType: 0,
    default: 'unknown',
    fieldName: 'steamTracingQuality',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'relativeHumidityPm',
    valueType: 2,
    fieldName: 'relativeHumidityPm',
    min: 0,
    max: 100,
    default: 50,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'input',
    label: 'Relative Humidity PM',
  },
  {
    keyword: 'relativeHumidityAm',
    valueType: 2,
    fieldName: 'relativeHumidityAm',
    min: 0,
    max: 100,
    default: 50,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'input',
    label: 'Relative Humidity AM',
  },
  {
    keyword: 'dewPointAm',
    valueType: 2,
    fieldName: 'dewPointAm',
    default: { USCustomary: 60.0, Metric: 15.0 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'dewPointPm',
    valueType: 2,
    fieldName: 'dewPointPm',
    default: { USCustomary: 60.0, Metric: 15.0 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'temperatureAm',
    valueType: 2,
    fieldName: 'temperatureAm',
    default: { USCustomary: 70.0, Metric: 21 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'temperaturePm',
    valueType: 2,
    fieldName: 'temperaturePm',
    default: { USCustomary: 70.0, Metric: 21 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'weightTheTwoLikelihoodPredVsExpertDesignFailureTime',
    valueType: 2,
    fieldName: 'weightTheTwoLikelihoodPredVsExpertDesignFailureTime',
    min: 0,
    max: 100,
    default: 25,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertDesignFailureSD',
    valueType: 2,
    fieldName: 'expertDesignFailureSD',
    min: 0,
    default: 1,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'weightTheTwoLikelihoodPredVsExperWetCorrosionRate',
    valueType: 2,
    fieldName: 'weightTheTwoLikelihoodPredVsExperWetCorrosionRate',
    min: 0,
    max: 100,
    default: 25,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertWetCorrosionRateSD',
    valueType: 2,
    min: 0,
    default: { USCustomary: 1.0, Metric: 0.025 },
    fieldName: 'expertWetCorrosionRateSD',
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertWetCorrosionRate',
    valueType: 2,
    min: 0,
    default: { USCustomary: 10.0, Metric: 0.25 },
    fieldName: 'expertWetCorrosionRate',
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertWetCorrosionRateConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'expertWetCorrosionRateConfidence',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertJacketingDesignFailureTimeConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'expertJacketingDesignFailureTimeConfidence',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertCoatingFailureTimeConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'expertCoatingFailureTimeConfidence',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertJacketingDesignFailureMean',
    valueType: 2,
    fieldName: 'expertJacketingDesignFailureMean',
    min: 0,
    default: 5,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'input',
    label: '',
  },
  {
    keyword: 'expertCoatingFailureTime',
    valueType: 2,
    fieldName: 'expertCoatingFailureTime',
    min: 0,
    default: 5,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'input',
    label: '',
  },
  {
    keyword: 'modelConfidenceWetCorrosionRate',
    valueType: 0,
    default: 'Low',
    fieldName: 'modelConfidenceWetCorrosionRate',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'modelConfidenceForCoatingDesignLife',
    valueType: 0,
    default: 'Low',
    fieldName: 'modelConfidenceForCoatingDesignLife',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'modelConfidenceForCorrosionFailureTime',
    valueType: 0,
    default: 'Low',
    fieldName: 'modelConfidenceForCorrosionFailureTime',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'modelConfidenceForJacketingDesignFailureTime',
    valueType: 0,
    default: 'Low',
    fieldName: 'modelConfidenceForJacketingDesignFailureTime',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'modelConfidenceForVaporBarrierFailureTime',
    valueType: 0,
    default: 'Low',
    fieldName: 'modelConfidenceForVaporBarrierFailureTime',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  // **********************************************************************************************************
  // **********************************************************************************************************
  //                                                Problem Type 1 INPUT
  // **********************************************************************************************************
  // **********************************************************************************************************
  {
    keyword: 'spacingValue',
    valueType: 2,
    fieldName: 'spacingValue',
    default: 5,
    min: 0,
    max: 60,
    initializer: undefined,
    type: 'input',
    tooltip: 'Spacing Value',
  },
  {
    keyword: 'discretizationSpacingDegree',
    valueType: 0,
    fieldName: 'discretizationSpacingDegree',
    default: 'Coarse Spacing',
    initializer: undefined,
    type: 'input',
    label: 'Discretization Spacing Degree',
  },
  {
    keyword: 'weightTheTwoLikelihoodPredVsExpertVaporBarrierDamageRate',
    valueType: 2,
    fieldName: 'weightTheTwoLikelihoodPredVsExpertVaporBarrierDamageRate',
    min: 0,
    max: 100,
    default: 25,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: '',
  },
  {
    keyword: 'weightTheTwoLikelihoodPredVsExpertJacketDamageRate',
    valueType: 2,
    fieldName: 'weightTheTwoLikelihoodPredVsExpertJacketDamageRate',
    min: 0,
    max: 100,
    default: 25,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: '',
  },
  {
    keyword: 'weightTheTwoLikelihoodPredVsExpertSealantDamageRate',
    valueType: 2,
    fieldName: 'weightTheTwoLikelihoodPredVsExpertSealantDamageRate',
    min: 0,
    max: 100,
    default: 25,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: '',
  },
  {
    keyword: 'weightTheTwoLikelihoodPredVsExpertCoatingDamageRate',
    valueType: 2,
    fieldName: 'weightTheTwoLikelihoodPredVsExpertCoatingDamageRate',
    min: 0,
    max: 100,
    default: 25,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: '',
  },
  {
    keyword: 'weightTheTwoLikelihoodPredVsExpertVaporBarrierFailureTime',
    valueType: 2,
    fieldName: 'weightTheTwoLikelihoodPredVsExpertVaporBarrierFailureTime',
    min: 0,
    max: 100,
    default: 25,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: '',
  },
  {
    keyword: 'weightTheTwoLikelihoodPredVsExpertCorrosionFailureTime',
    valueType: 2,
    fieldName: 'weightTheTwoLikelihoodPredVsExpertCorrosionFailureTime',
    min: 0,
    max: 100,
    default: 25,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: '',
  },
  {
    keyword: 'weightTheTwoLikelihoodPredVsExpertSealantFailureTime',
    valueType: 2,
    fieldName: 'weightTheTwoLikelihoodPredVsExpertSealantFailureTime',
    min: 0,
    max: 100,
    default: 25,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: '',
  },
  {
    keyword: 'weightTheTwoLikelihoodPredVsExpertCoatingFirstFailureTime',
    valueType: 2,
    fieldName: 'weightTheTwoLikelihoodPredVsExpertCoatingFirstFailureTime',
    min: 0,
    max: 100,
    default: 25,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: '',
  },
  {
    keyword: 'expertVaporBarrierDamageRateSD',
    valueType: 2,
    fieldName: 'expertVaporBarrierDamageRateSD',
    min: 0,
    default: 1,
    initializer: undefined,
    units: {
      USCustomary: '%/yr',
      Metric: '%/yr',
    },
    type: 'input',
    label: '',
  },
  {
    keyword: 'expertJacketDamageRateSD',
    valueType: 2,
    min: 0,
    default: 1,
    units: {
      USCustomary: '%/yr',
      Metric: '%/yr',
    },
    fieldName: 'expertJacketDamageRateSD',
    initializer: undefined,
    type: 'input',
    label: '',
  },
  {
    keyword: 'expertSealantDamageRateSD',
    valueType: 2,
    min: 0,
    default: 1,
    units: {
      USCustomary: '%/yr',
      Metric: '%/yr',
    },
    fieldName: 'expertSealantDamageRateSD',
    initializer: undefined,
    type: 'input',
    label: '',
  },
  {
    keyword: 'expertCoatingDamageRateSD',
    valueType: 2,
    min: 0,
    default: 1,
    units: {
      USCustomary: '%/yr',
      Metric: '%/yr',
    },
    fieldName: 'expertCoatingDamageRateSD',
    initializer: undefined,
    type: 'input',
    label: '',
  },
  {
    keyword: 'expertVaporBarrierFailureTimeSD',
    valueType: 2,
    fieldName: 'expertVaporBarrierFailureTimeSD',
    min: 0,
    default: 1,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'input',
    label: '',
  },
  {
    keyword: 'expertCorrosionFailureSD',
    valueType: 2,
    min: 0,
    default: 1,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    fieldName: 'expertCorrosionFailureSD',
    initializer: undefined,
    type: 'input',
    label: '',
  },
  {
    keyword: 'expertSealantFailureTimeSD',
    valueType: 2,
    min: 0,
    default: 1,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    fieldName: 'expertSealantFailureTimeSD',
    initializer: undefined,
    type: 'input',
    label: '',
  },
  {
    keyword: 'expertCoatingFirstFailureTimeSD',
    valueType: 2,
    min: 0,
    default: 1,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    fieldName: 'expertCoatingFirstFailureTimeSD',
    initializer: undefined,
    type: 'input',
    label: '',
  },
  {
    keyword: 'expertEffectiveCorrosionRateSD',
    valueType: 2,
    min: 0,
    default: { USCustomary: 1.0, Metric: 0.025 },
    fieldName: 'expertEffectiveCorrosionRateSD',
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    initializer: undefined,
    type: 'input',
    label: '',
  },
  {
    keyword: 'expertInstantaneousCorrosionRateSD',
    valueType: 2,
    min: 0,
    default: { USCustomary: 1.0, Metric: 0.025 },
    fieldName: 'expertInstantaneousCorrosionRateSD',
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    initializer: undefined,
    type: 'input',
    label: '',
  },
  {
    keyword: 'expertExternalExposureFractionSD',
    valueType: 2,
    min: 0,
    default: 1,
    fieldName: 'expertExternalExposureFractionSD',
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: '',
  },
  {
    keyword: 'weightTheTwoLikelihoodPredVsExpertEffectiveCorrosionRate',
    valueType: 2,
    fieldName: 'weightTheTwoLikelihoodPredVsExpertEffectiveCorrosionRate',
    min: 0,
    max: 100,
    default: 25,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: '',
  },
  {
    keyword: 'weightTheTwoLikelihoodPredVsExpertInstantaneousCorrosionRate',
    valueType: 2,
    fieldName: 'weightTheTwoLikelihoodPredVsExpertInstantaneousCorrosionRate',
    min: 0,
    max: 100,
    default: 25,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: '',
  },
  {
    keyword: 'weightTheTwoLikelihoodPredVsExpertExternalExposureFraction',
    valueType: 2,
    fieldName: 'weightTheTwoLikelihoodPredVsExpertExternalExposureFraction',
    min: 0,
    max: 100,
    default: 25,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: '',
  },
  {
    keyword: 'weightTheTwoLikelihoodPredVsExpertDamageRate',
    valueType: 2,
    fieldName: 'weightTheTwoLikelihoodPredVsExpertDamageRate',
    min: 0,
    max: 100,
    default: 25,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: 'External Damage Rate Weight Likelihood Value',
  },
  {
    keyword: 'weightTheTwoLikelihoodPredVsExpertJacketingCorrosionFailureTime',
    valueType: 2,
    fieldName: 'weightTheTwoLikelihoodPredVsExpertJacketingCorrosionFailureTime',
    min: 0,
    max: 100,
    default: 25,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: 'Jacketing Corrosion Time Weight Likelihood Value',
  },
  {
    keyword: 'weightTheTwoLikelihoodPredVsExpertJacketingSealantFailureTime',
    valueType: 2,
    fieldName: 'weightTheTwoLikelihoodPredVsExpertJacketingSealantFailureTime',
    min: 0,
    max: 100,
    default: 25,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: 'Jacketing Sealant Time Weight Likelihood Value',
  },
  {
    keyword: 'weightTheTwoLikelihoodPredVsExpertAnnualProbabilityMissingJacketing',
    valueType: 2,
    fieldName: 'weightTheTwoLikelihoodPredVsExpertAnnualProbabilityMissingJacketing',
    min: 0,
    max: 100,
    default: 25,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: 'Annual Probability Missing Weight Likelihood Value',
  },
  {
    keyword: 'weightTheTwoLikelihoodPredVsExpertVaporBarrierSealantFailureTime',
    valueType: 2,
    fieldName: 'weightTheTwoLikelihoodPredVsExpertVaporBarrierSealantFailureTime',
    min: 0,
    max: 100,
    default: 25,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: 'Vapor Barrier Sealant Time Weight Likelihood Value',
  },
  {
    keyword: 'weightTheTwoLikelihoodPredVsExpertAnnualProbabilityMissingVaporBarrier',
    valueType: 2,
    fieldName: 'weightTheTwoLikelihoodPredVsExpertAnnualProbabilityMissingVaporBarrier',
    min: 0,
    max: 100,
    default: 25,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: 'Annual Probability Missing Vapor Barrier Weight Likelihood Value',
  },
  {
    keyword: 'weightTheTwoLikelihoodPredVsExpertCoatingFailureTime',
    valueType: 2,
    fieldName: 'weightTheTwoLikelihoodPredVsExpertCoatingFailureTime',
    min: 0,
    max: 100,
    default: 25,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: 'Coating Failure Time Weight Likelihood Value',
  },
  {
    keyword: 'expertDamageRateSD',
    valueType: 2,
    fieldName: 'expertDamageRateSD',
    min: 0,
    default: { USCustomary: 1.0, Metric: 0.025 },
    initializer: undefined,
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    type: 'input',
    label: 'Expert External Damage Rate Mean',
  },
  {
    keyword: 'expertCoatingFailureTimeSD',
    valueType: 2,
    fieldName: 'expertCoatingFailureTimeSD',
    min: 0,
    default: 1,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    initializer: undefined,
    type: 'input',
    label: 'Expert Coating Failure Time Standard Deviation',
  },
  {
    keyword: 'jacketingFailureTimeSDInput',
    valueType: 2,
    fieldName: 'jacketingFailureTimeSDInput',
    min: 0,
    default: 1,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    initializer: undefined,
    type: 'input',
    label: 'Expert Jacketing Corrosion Failure Time Standard Deviation',
  },
  {
    keyword: 'jacketingFailureTimeSealantSD',
    valueType: 2,
    fieldName: 'jacketingFailureTimeSealantSD',
    min: 0,
    default: 1,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    initializer: undefined,
    type: 'input',
    label: 'Expert Jacketing Sealant Failure Time Standard Deviation',
  },
  {
    keyword: 'expertVaporBarrierSealantFailureTimeSD',
    valueType: 2,
    fieldName: 'expertVaporBarrierSealantFailureTimeSD',
    min: 0,
    default: 1,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    initializer: undefined,
    type: 'input',
    label: 'Expert Vapor Barrier Sealant Failure Time Standard Deviation',
  },
  {
    keyword: 'weightTheTwoLikelihoodPredVsExpertCoatingFailureTime',
    valueType: 2,
    fieldName: 'weightTheTwoLikelihoodPredVsExpertCoatingFailureTime',
    min: 0,
    max: 100,
    default: 25,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: 'Coating Failure TIme Weight Likelihood Value',
  },
  {
    keyword: 'whichExpertModelMethodFT',
    valueType: 0,
    fieldName: 'whichExpertModelMethodFT',
    default: 'Expert Prior Caused by Prediction',
    initializer: undefined,
    type: 'input',
    label: 'Expert Prior Model Option',
  },
  {
    keyword: 'whichExpertModelMethodDR',
    valueType: 0,
    fieldName: 'whichExpertModelMethodDR',
    default: 'Expert Prior Caused by Prediction',
    initializer: undefined,
    type: 'input',
    label: 'Expert Prior Model Option',
  },
  {
    keyword: 'installReplaceDate',
    valueType: 0,
    fieldName: 'installReplaceDate',
    default: '2000-01-01',
    initializer: undefined,
    type: 'input',
    label: 'Component Install/Last Replacement Date',
  },
  {
    keyword: undefined,
    valueType: 2,
    fieldName: 'jacketingVaporBarrierFailureLikelihood',
    default: [[70], [30]],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 2,
    fieldName: 'jacketingFailureLikelihood',
    default: [[45], [10], [45]],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 2,
    fieldName: 'dewPointHistory',
    default: [[null], [null]],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 2,
    fieldName: 'tempHistory',
    default: [[null], [null]],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 2,
    fieldName: 'ambientTempHistory',
    default: [[null], [null]],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 2,
    fieldName: 'relativeHumidityHistory',
    default: [[null], [null]],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: 'maxTime',
    valueType: 2,
    fieldName: 'maxTime',
    min: 1,
    default: 100,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'input',
    label: 'Component Design Life',
  },
  {
    keyword: 'nominalThickness',
    valueType: 2,
    fieldName: 'nominalThickness',
    min: { USCustomary: 0.00001, Metric: 0.000254 },
    max: { USCustomary: 5, Metric: 125 },
    default: { USCustomary: 0.5, Metric: 12.7 },
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'input',
    label: 'Component Nominal Thickness',
  },
  {
    keyword: 'undertolerance',
    valueType: 2,
    fieldName: 'undertolerance',
    min: 0.00001,
    default: 12.5,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'input',
    label: 'Component Undertolerance',
  },
  {
    keyword: 'failureThickness',
    valueType: 2,
    fieldName: 'failureThickness',
    min: { USCustomary: 0.0, Metric: 0.0 },
    default: { USCustomary: 0.0, Metric: 0.0 },
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'input',
    label: 'Component Failure Thickness',
  },
  {
    keyword: 'failureThicknessConfidence',
    valueType: 0,
    fieldName: 'failureThicknessConfidence',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Failure Thickness Confidence',
  },
  {
    keyword: 'failureThicknessMethod',
    valueType: 0,
    fieldName: 'failureThicknessMethod',
    default: 'specify',
    initializer: undefined,
    type: 'input',
    label: 'Failure Thickness Method',
  },
  {
    keyword: 'failureThicknessVarianceType',
    valueType: 0,
    fieldName: 'failureThicknessVarianceType',
    default: 'confidence',
    initializer: undefined,
    type: 'input',
    label: 'Failure Thickness Variance Type',
  },
  {
    keyword: 'failureThicknessSD',
    valueType: 2,
    fieldName: 'failureThicknessSD',
    min: 0,
    default: { USCustomary: 0.1, Metric: 2.5 },
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    initializer: undefined,
    type: 'input',
    label: 'Failure Thickness SD',
  },
  {
    keyword: 'failureThicknessCV',
    valueType: 2,
    fieldName: 'failureThicknessCV',
    min: 0,
    default: 0.2,
    initializer: undefined,
    type: 'input',
    label: 'Failure Thickness CV',
  },
  {
    keyword: 'operatingTemperatureKnown',
    valueType: 0,
    fieldName: 'operatingTemperatureKnown',
    default: 'Yes',
    initializer: undefined,
    type: 'input',
    label: 'Is Operating Temperature Known?',
  },
  {
    keyword: 'operatingTemperature',
    valueType: 2,
    fieldName: 'operatingTemperature',
    default: { USCustomary: 70.0, Metric: 21 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'Operating Temperature',
  },
  {
    keyword: 'ambientTemperature',
    valueType: 0,
    fieldName: 'ambientTemperature',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Ambient Temperature',
  },
  {
    keyword: 'internalPressure',
    valueType: 2,
    fieldName: 'internalPressure',
    min: { USCustomary: 0.0, Metric: 0.0 },
    default: { USCustomary: 100.0, Metric: 0.7 },
    initializer: undefined,
    units: {
      USCustomary: 'psi',
      Metric: 'MPa',
    },
    type: 'input',
    label: 'Internal Pressure',
  },
  {
    keyword: 'flowStress',
    valueType: 2,
    fieldName: 'flowStress',
    min: { USCustomary: 0.0, Metric: 0.0 },
    default: { USCustomary: 40.0, Metric: 275.0 },
    initializer: undefined,
    units: {
      USCustomary: 'ksi',
      Metric: 'MPa',
    },
    type: 'input',
    label: 'Flow Stress',
  },
  {
    keyword: 'innerDiameter',
    valueType: 2,
    fieldName: 'innerDiameter',
    min: { USCustomary: 0.0, Metric: 0.0 },
    default: { USCustomary: 12.0, Metric: 304.8 },
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'input',
    label: 'Inner Diameter',
  },
  {
    keyword: 'innerDiameterFE',
    valueType: 2,
    fieldName: 'innerDiameterFE',
    min: { USCustomary: 0.0, Metric: 0.0 },
    default: { USCustomary: 15, Metric: 5 },
    initializer: undefined,
    units: {
      USCustomary: 'ft',
      Metric: 'm',
    },
    type: 'input',
    label: 'Inner Diameter',
  },
  {
    keyword: 'operatingTemperatureConfidence',
    valueType: 0,
    fieldName: 'operatingTemperatureConfidence',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Operating Temperature Confidence',
  },
  {
    keyword: 'operatingTemperatureVarianceType',
    valueType: 0,
    fieldName: 'operatingTemperatureVarianceType',
    default: 'confidence',
    initializer: undefined,
    type: 'input',
    label: 'Operating Temperature Variance Type',
  },
  {
    keyword: 'relativeHumidityInputType',
    valueType: 0,
    fieldName: 'relativeHumidityInputType',
    default: 'constant',
    initializer: undefined,
    type: 'input',
    label: 'Relative Humidity Input Type',
  },
  {
    keyword: 'ambientTemperatureInputType',
    valueType: 0,
    fieldName: 'ambientTemperatureInputType',
    default: 'constant',
    initializer: undefined,
    type: 'input',
    label: 'Ambient Temperature Input Type',
  },
  {
    keyword: 'operatingTemperatureInputType',
    valueType: 0,
    fieldName: 'operatingTemperatureInputType',
    default: 'constant',
    initializer: undefined,
    type: 'input',
    label: 'Operating Temperature Input Type',
  },
  {
    keyword: 'annualRainfall2',
    valueType: 0,
    fieldName: 'annualRainfall2',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Annual Rainfall',
  },
  {
    keyword: 'externalEnvironment1',
    valueType: 0,
    fieldName: 'externalEnvironment1',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'environmentCorrosivity',
    fieldName: 'environmentCorrosivity',
    valueType: 0,
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Environment Corrosivity',
  },
  {
    keyword: 'annualRainfall',
    fieldName: 'annualRainfall',
    valueType: 0,
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Annual Rainfall',
  },
  {
    keyword: 'annualRainfall1',
    fieldName: 'annualRainfall1',
    valueType: 0,
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Annual Rainfall',
  },
  {
    keyword: 'externalEnvironment',
    fieldName: 'externalEnvironment',
    valueType: 0,
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Annual Rainfall',
  },
  {
    keyword: 'environment',
    fieldName: 'environment',
    valueType: 0,
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Environment Severity',
  },
  {
    keyword: 'environmentConfidence',
    fieldName: 'environmentConfidence',
    valueType: 0,
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Environment Confidence',
  },
  {
    keyword: 'operatingTemperature1',
    fieldName: 'operatingTemperature1',
    valueType: 2,
    default: { USCustomary: 100, Metric: 50 },
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'Operating Temperature',
  },
  {
    keyword: 'expertExposureFractionMean',
    fieldName: 'expertExposureFractionMean',
    valueType: 2,
    default: 50,
    min: 0,
    max: 100,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: 'Fraction of Year Exposed to Environment Mean',
  },
  {
    keyword: 'expertExposureConfidence',
    valueType: 0,
    fieldName: 'expertExposureConfidence',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Expert Fraction of Year Exposed to Environment Confidence',
  },
  {
    keyword: 'expertExposureConfidenceKnown',
    valueType: 0,
    fieldName: 'expertExposureConfidenceKnown',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Is Exposure Fraction Known?',
  },
  {
    keyword: 'exposureConfidence',
    valueType: 0,
    fieldName: 'exposureConfidence',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Fraction of Year Exposed to Environment Confidence',
  },
  {
    keyword: 'expertAnnualProbabilityMissingVaporBarrier',
    valueType: 2,
    fieldName: 'expertAnnualProbabilityMissingVaporBarrier',
    min: 0,
    max: 100,
    default: 25,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: 'Expert Annual Probability of Missing Vapor Barrier',
  },
  {
    keyword: 'expertMissingVaporBarrierConfidence',
    valueType: 0,
    fieldName: 'expertMissingVaporBarrierConfidence',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Expert Missing Vapor Barrier Confidence',
  },
  {
    keyword: 'expertAnnualProbabilityMissingJacketing',
    valueType: 2,
    fieldName: 'expertAnnualProbabilityMissingJacketing',
    min: 0,
    max: 100,
    default: 25,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: 'Expert Annual Probability of Missing Jacketing',
  },
  {
    keyword: 'expertMissingJacketingConfidence',
    valueType: 0,
    fieldName: 'expertMissingJacketingConfidence',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Expert Missing Jacketing Confidence',
  },
  {
    keyword: 'expertConfidence',
    valueType: 0,
    fieldName: 'expertConfidence',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Expert External Damage Rate Confidence',
  },
  {
    keyword: 'jacketingFailureTimeMean',
    valueType: 2,
    fieldName: 'jacketingFailureTimeMean',
    min: 0.0,
    default: 5.0,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'input',
    label: '"Expert Jacketing Failure Time Mean',
  },
  {
    keyword: 'expertJacketingConfidence',
    valueType: 0,
    fieldName: 'expertJacketingConfidence',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Expert Jacketing Failure Time Confidence',
  },
  {
    keyword: 'expertJacketingSealantConfidence',
    valueType: 0,
    fieldName: 'expertJacketingSealantConfidence',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Expert Jacketing Failure Time Sealant Confidence',
  },
  {
    keyword: 'jacketingFailureTimeSealantMean',
    valueType: 2,
    fieldName: 'jacketingFailureTimeSealantMean',
    min: 0.0,
    default: 5.0,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'input',
    label: '"Expert Jacketing Failure Time Sealant Mean',
  },
  {
    keyword: 'coatingFailureTimeMean',
    valueType: 2,
    fieldName: 'coatingFailureTimeMean',
    min: 0.0,
    default: 10.0,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'input',
    label: 'Expert Coating Failure Time Mean',
  },
  {
    keyword: 'expertCoatingConfidence',
    valueType: 0,
    fieldName: 'expertCoatingConfidence',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Expert Coating Failure Time Confidence',
  },
  {
    keyword: 'priorNdeInspection',
    valueType: 0,
    fieldName: 'priorNdeInspection',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Prior NDE Inspection?',
  },
  {
    keyword: 'lastNdeInspectionTime',
    valueType: 2,
    min: 0,
    fieldName: 'lastNdeInspectionTime',
    default: 5.0,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'input',
    label: 'Last NDE Inspection Time',
  },
  {
    keyword: 'lastNonIntrusiveVisualInspectionTime',
    valueType: 2,
    min: 0,
    fieldName: 'lastNonIntrusiveVisualInspectionTime',
    default: 5.0,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'input',
    label: 'Last Non-Intrusive Visual Inspection Time',
  },
  {
    keyword: 'priorNonIntrusiveVisualInspection',
    valueType: 0,
    fieldName: 'priorNonIntrusiveVisualInspection',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Prior Non-Intrusive Visual Inspection?',
  },
  {
    keyword: 'vteCorrosionSeverity',
    valueType: 0,
    fieldName: 'vteCorrosionSeverity',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'VTE Corrosion Severity',
  },
  {
    keyword: 'ndeCorrosionSeverity',
    valueType: 0,
    fieldName: 'ndeCorrosionSeverity',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'NDE Corrosion Severity',
  },
  {
    keyword: 'lastIntrusiveVisualInspectionTime',
    valueType: 2,
    min: 0,
    fieldName: 'lastIntrusiveVisualInspectionTime',
    default: 5.0,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'input',
    label: 'Last Intrusive Visual Inspection Time',
  },
  {
    keyword: 'priorIntrusiveVisualInspection',
    valueType: 0,
    fieldName: 'priorIntrusiveVisualInspection',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Prior Intrusive Visual Inspection?',
  },
  {
    keyword: 'vtiCorrosionSeverity',
    valueType: 0,
    fieldName: 'vtiCorrosionSeverity',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'VTI Corrosion Severity',
  },
  {
    keyword: 'insulationWetAtVTI',
    valueType: 0,
    fieldName: 'insulationWetAtVTI',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Insulation Wet at VTI?',
  },
  {
    keyword: 'measurementError',
    valueType: 2,
    fieldName: 'measurementError',
    min: 0,
    default: 10,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'input',
    label: 'NDE Measurement Error',
  },
  {
    keyword: 'measurementErrorThickness',
    valueType: 2,
    fieldName: 'measurementErrorThickness',
    min: { USCustomary: 0.0, Metric: 0.0 },
    default: { USCustomary: 100.0, Metric: 2.54 },
    initializer: undefined,
    units: {
      USCustomary: 'mil',
      Metric: 'mm',
    },
    type: 'input',
    label: 'NDE Measurement Error Thickness',
  },
  {
    keyword: 'ndeMeasurementErrorType',
    valueType: 0,
    fieldName: 'ndeMeasurementErrorType',
    default: 'percent',
    initializer: undefined,
    type: 'input',
    label: 'NDE Measurement Error Type',
  },
  {
    keyword: 'expertDamageRate',
    valueType: 2,
    fieldName: 'expertDamageRate',
    min: 0,
    initializer: undefined,
    default: { USCustomary: 10.0, Metric: 0.25 },
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    type: 'input',
    label: 'Expert External Damage Rate Mean',
  },
  {
    keyword: 'jacketInstallQuality',
    valueType: 0,
    fieldName: 'jacketInstallQuality',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Jacket Install Quality',
  },
  {
    keyword: 'attachments',
    valueType: 0,
    fieldName: 'attachments',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Attachments?',
  },
  {
    keyword: 'pipingComplexity',
    valueType: 0,
    fieldName: 'pipingComplexity',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Piping Complexity?',
  },
  {
    keyword: 'accessibility',
    valueType: 0,
    fieldName: 'accessibility',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Piping Accessibility?',
  },
  {
    keyword: 'supportRings',
    valueType: 0,
    fieldName: 'supportRings',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Support Rings?',
  },
  {
    keyword: 'jacketingMaterial',
    valueType: 0,
    fieldName: 'jacketingMaterial',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Jacketing Material',
  },
  {
    keyword: 'sealantFailure',
    valueType: 0,
    fieldName: 'sealantFailure',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'VTE Jacketing Sealant Condition',
  },
  {
    keyword: 'coatingInstallQuality',
    valueType: 0,
    fieldName: 'coatingInstallQuality',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Coating Install Quality',
  },
  {
    keyword: 'coatingType',
    valueType: 0,
    fieldName: 'coatingType',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Coating Material Quality',
  },
  {
    keyword: 'coatingMaterial',
    valueType: 0,
    fieldName: 'coatingMaterial',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Coating Material',
  },
  {
    keyword: 'jacketingFailure',
    valueType: 0,
    fieldName: 'jacketingFailure',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'VTE Jacketing Failure?',
  },
  {
    keyword: 'coatingFailureVTI',
    valueType: 0,
    fieldName: 'coatingFailureVTI',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'VTI Coating Failure?',
  },
  {
    keyword: 'coatingFailureVTE',
    valueType: 0,
    fieldName: 'coatingFailureVTE',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'VTE Coating Failure?',
  },
  {
    keyword: 'coatingFailureNDE',
    valueType: 0,
    fieldName: 'coatingFailureNDE',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'NDE Coating Failure?',
  },
  {
    keyword: 'pooling',
    valueType: 0,
    fieldName: 'pooling',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Water Pooling?',
  },
  {
    keyword: 'properDrainage',
    valueType: 0,
    fieldName: 'properDrainage',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Proper Drainage?',
  },
  {
    keyword: 'waterRepellent',
    valueType: 0,
    fieldName: 'waterRepellent',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Water Repellent?',
  },
  {
    keyword: 'airSoilInterface',
    valueType: 0,
    fieldName: 'airSoilInterface',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Air to Soil Interface?',
  },
  {
    keyword: 'steamTracing',
    valueType: 0,
    fieldName: 'steamTracing',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Steam Tracing?',
  },
  {
    keyword: 'internalDamageRate',
    valueType: 2,
    fieldName: 'internalDamageRate',
    min: 0,
    default: { USCustomary: 10.0, Metric: 0.25 },
    initializer: undefined,
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    type: 'input',
    label: 'Expert Internal Damage Rate Mean',
  },
  {
    keyword: 'internalDamageRateConfidence',
    valueType: 0,
    fieldName: 'internalDamageRateConfidence',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Expert Internal Damage Rate Confidence',
  },
  {
    keyword: 'damageRateConfidence',
    valueType: 0,
    fieldName: 'damageRateConfidence',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Damage Rate Model Confidence',
  },
  {
    keyword: 'measurementErrorConfidence',
    valueType: 0,
    fieldName: 'measurementErrorConfidence',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'NDE Measurement Error Confidence',
  },
  {
    keyword: 'measuredThickness',
    valueType: 2,
    fieldName: 'measuredThickness',
    min: { USCustomary: 0.0, Metric: 0.0 },
    default: { USCustomary: 0.5, Metric: 12.7 },
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'input',
    label: 'Measured Thickness',
  },
  {
    keyword: 'decisionsFlag',
    valueType: 0,
    fieldName: 'decisionsFlag',
    default: 'False',
    initializer: undefined,
    type: 'input',
    label: 'Decisions Flag',
  },
  {
    keyword: 'decisionsStrategy',
    valueType: 0,
    fieldName: 'decisionsStrategy',
    default: '0',
    initializer: undefined,
    type: 'input',
    label: 'Decisions Strategy',
  },
  {
    keyword: 'reseal',
    valueType: 0,
    fieldName: 'reseal',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Reseal?',
  },
  {
    keyword: 'rejacket',
    valueType: 0,
    fieldName: 'rejacket',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Rejacket?',
  },
  {
    keyword: 'recoat',
    valueType: 0,
    fieldName: 'recoat',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Recoat?',
  },
  {
    keyword: 'recoatCosts',
    valueType: 2,
    fieldName: 'recoatCosts',
    min: 0,
    default: 1,
    initializer: undefined,
    type: 'input',
    label: 'Recoat Costs ($)',
  },
  {
    keyword: 'resealCosts',
    valueType: 2,
    fieldName: 'resealCosts',
    min: 0,
    default: 1,
    initializer: undefined,
    type: 'input',
    label: 'Reseal Costs ($)',
  },
  {
    keyword: 'rejacketCosts',
    valueType: 2,
    fieldName: 'rejacketCosts',
    min: 0,
    default: 10,
    initializer: undefined,
    type: 'input',
    label: 'Rejacket Costs ($)',
  },
  {
    keyword: 'operatingProfit',
    valueType: 2,
    fieldName: 'operatingProfit',
    min: 0,
    default: 1,
    initializer: undefined,
    type: 'input',
    label: 'Annual Operating Profit ($)',
  },
  {
    keyword: 'replacementCost',
    valueType: 2,
    fieldName: 'replacementCost',
    min: 0,
    default: 100,
    initializer: undefined,
    type: 'input',
    label: 'Replacement Cost ($)',
  },
  {
    keyword: 'failureCost',
    valueType: 2,
    fieldName: 'failureCost',
    min: 0,
    default: 1000,
    initializer: undefined,
    type: 'input',
    label: 'Failure Cost ($)',
  },
  {
    keyword: 'jacketConditionGeneral',
    valueType: 0,
    fieldName: 'jacketConditionGeneral',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'VTE Jacketing Condition (General)',
  },
  {
    keyword: 'jacketCondition',
    valueType: 0,
    fieldName: 'jacketCondition',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'VTE Jacketing Condition (Corrosion)',
  },
  {
    keyword: 'branchesGeometryChanges',
    valueType: 0,
    fieldName: 'branchesGeometryChanges',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Branches or Geometry Changes?',
  },
  {
    keyword: 'lowPointWaterTrap',
    valueType: 0,
    fieldName: 'lowPointWaterTrap',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Low Point or Water Trap?',
  },
  {
    keyword: 'insulationType',
    valueType: 0,
    fieldName: 'insulationType',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Insulation Type',
  },
  {
    keyword: 'coatingPostInstallQAQC',
    valueType: 0,
    fieldName: 'coatingPostInstallQAQC',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Coating Post Install QA/QC',
  },
  {
    keyword: 'jacketPostInstallQAQC',
    valueType: 0,
    fieldName: 'jacketPostInstallQAQC',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Jacket Post Install QA/QC',
  },
  {
    keyword: 'coatingDesignedForCui',
    valueType: 0,
    fieldName: 'coatingDesignedForCui',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Coating Designed for CUI?',
  },
  {
    keyword: 'coatingInstalledInTheField',
    valueType: 0,
    fieldName: 'coatingInstalledInTheField',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Coating Installed in the Field?',
  },
  {
    keyword: 'uvExposure',
    valueType: 0,
    fieldName: 'uvExposure',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'UV Exposure',
  },
  {
    keyword: 'ozoneExposure',
    valueType: 0,
    fieldName: 'ozoneExposure',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Ozone Exposure',
  },
  {
    keyword: 'highTempSilicone',
    valueType: 0,
    fieldName: 'highTempSilicone',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'High Temperature Silicone',
  },

  {
    keyword: 'highWindLikelihood',
    valueType: 0,
    fieldName: 'highWindLikelihood',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'High Wind Likelihood',
  },
  {
    keyword: 'componentType',
    valueType: 0,
    fieldName: 'componentType',
    default: 'Horizontal Run',
    initializer: undefined,
    type: 'input',
    label: 'Component Type',
  },
  {
    keyword: 'horizontalLapJoints',
    valueType: 0,
    fieldName: 'horizontalLapJoints',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Horizontal Lap Joints',
  },
  {
    keyword: 'verticalTelescoping',
    valueType: 0,
    fieldName: 'verticalTelescoping',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Vertical Telescoping',
  },
  {
    keyword: 'endCapType',
    valueType: 0,
    fieldName: 'endCapType',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'End Cap Quality',
  },
  {
    keyword: 'missingAtVTE',
    valueType: 0,
    fieldName: 'missingAtVTE',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Jacketing Missing at VTE',
  },
  {
    keyword: 'pastMaintenance',
    valueType: 0,
    fieldName: 'pastMaintenance',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Past Maintenance or Port Inspection',
  },
  {
    keyword: 'vaporBarrierReplaced',
    valueType: 0,
    fieldName: 'vaporBarrierReplaced',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Vapor Barrier Replaced?',
  },
  {
    keyword: 'vaporBarrierSealantReplaced',
    valueType: 0,
    fieldName: 'vaporBarrierSealantReplaced',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Vapor Barrier Sealant Replaced?',
  },
  {
    keyword: 'protrusionsCovered',
    valueType: 0,
    fieldName: 'protrusionsCovered',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Protrusions Covered to Shed Water',
  },
  {
    keyword: 'inspectionInputType',
    valueType: 0,
    fieldName: 'inspectionInputType',
    default: 'lastSingle',
    initializer: undefined,
    type: 'input',
    label: 'Inspection Input Type',
  },
  {
    keyword: 'installDate',
    valueType: 0,
    fieldName: 'installDate',
    default: '',
    initializer: undefined,
    type: 'input',
    label: 'Installation Date',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'inspectionDatesAll',
    default: [['01/01/2000'], ['01/01/2010'], ['01/01/2020']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'inspectionVTEBool',
    default: [['No'], ['No'], ['No']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'inspectionVTIBool',
    default: [['No'], ['No'], ['No']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'inspectionNDEBool',
    default: [['No'], ['No'], ['No']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'maintenanceBoolTable',
    default: [['No'], ['No'], ['No']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'inspectionMaintenanceTable',
    default: {
      USCustomary: [['', '', '', ''], ['', '', '', '']],
      Metric: [['', '', '', ''], ['', '', '', '']],
    },
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'maintenanceTableNoCondensation',
    default: {
      USCustomary: [['', '', '', ''], ['', '', '', '']],
      Metric: [['', '', '', ''], ['', '', '', '']],
    },
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'maintenanceTableCondensation',
    default: {
      USCustomary: [['', '', '', ''], ['', '', '', '']],
      Metric: [['', '', '', ''], ['', '', '', '']],
    },
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'maintenanceTableCondensationForHybridMethod',
    default: {
      USCustomary: [['', '', ''], ['', '', '']],
      Metric: [['', '', ''], ['', '', '']],
    },
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'maintenanceDates1',
    default: [['01/01/2000'], ['01/01/2010'], ['01/01/2020']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'maintenanceDates2',
    default: [['01/01/2000'], ['01/01/2010'], ['01/01/2020']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'componentReplacedTable1',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'componentReplacedTable2',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'coatingReplacedTable1',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'coatingReplacedTable2',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'jacketingReplacedTable1',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'jacketingReplacedTable2',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'jacketingReplacedSealantTable1',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'jacketingReplacedSealantTable2',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'vaporBarrierReplacedTable',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'vaporBarrierSealantReplacedTable',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'vteTable',
    default: [],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'vteTableSS',
    default: {
      USCustomary: [['', '', '', ''], ['', '', '', '']],
      Metric: [['', '', '', ''], ['', '', '', '']],
    },
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'vteTablePROB3',
    default: {
      USCustomary: [['', '', '', ''], ['', '', '', '']],
      Metric: [['', '', '', ''], ['', '', '', '']],
    },
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'vteTableSSPROB3',
    default: {
      USCustomary: [['', '', '', ''], ['', '', '', '']],
      Metric: [['', '', '', ''], ['', '', '', '']],
    },
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'inspectionDatesVTE1',
    default: [['01/01/2000'], ['01/01/2010'], ['01/01/2020']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'inspectionDatesVTE2',
    default: [['01/01/2000'], ['01/01/2010'], ['01/01/2020']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'inspectionDatesVTE3',
    default: [['01/01/2000'], ['01/01/2010'], ['01/01/2020']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'inspectionDatesVTE4',
    default: [['01/01/2000'], ['01/01/2010'], ['01/01/2020']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'vteJacketConditionTable1',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'vteJacketConditionTable2',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'vteJacketConditionTable3',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'vteJacketConditionTable4',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'vteJacketConditionGeneralTable3',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'vteJacketConditionGeneralTable4',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'vteSealantConditionTable1',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'vteSealantConditionTable2',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'vteSealantConditionTable3',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'vteSealantConditionTable4',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'vteJacketMissingTable1',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'vteJacketMissingTable2',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'vteJacketMissingTable3',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'vteJacketMissingTable4',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'vteCorrosionSeverityTable',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'vteCoatingConditionTable1',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'vteCoatingConditionTable2',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'vteCoatingConditionTable3',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'vteCoatingConditionTable4',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'vtiTable',
    default: {
      USCustomary: [['', '', '', ''], ['', '', '', '']],
      Metric: [['', '', '', ''], ['', '', '', '']],
    },
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'inspectionDatesVTI',
    default: [['01/01/2000'], ['01/01/2010'], ['01/01/2020']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'vtiInsulationWetTable',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'vtiCorrosionSeverityTable',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'vtiCoatingConditionTable',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'ndeTable',
    default: {
      USCustomary: [['', '', '', ''], ['', '', '', '']],
      Metric: [['', '', '', ''], ['', '', '', '']],
    },
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'ndeTableSS',
    default: {
      USCustomary: [['', '', '', ''], ['', '', '', '']],
      Metric: [['', '', '', ''], ['', '', '', '']],
    },
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'inspectionDatesNDE1',
    default: [['01/01/2000'], ['01/01/2010'], ['01/01/2020']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'inspectionDatesNDE2',
    default: [['01/01/2000'], ['01/01/2010'], ['01/01/2020']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'ndeCoatingConditionTable1',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'ndeCoatingConditionTable2',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'ndeCorrosionSeverityTable1',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'ndeCorrosionSeverityTable2',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'ndeCrackSeverityTable',
    default: [['unknown'], ['unknown'], ['unknown']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 2,
    fieldName: 'measuredFlawDepthTable',
    default: [[1], [1], [1]],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 2,
    fieldName: 'measuredThicknessTable1',
    default: [[1], [1], [1]],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 2,
    fieldName: 'measuredThicknessTable2',
    default: [[1], [1], [1]],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'ndeMeasurementErrorTypeTable1',
    default: [['percent'], ['percent'], ['percent']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'ndeMeasurementErrorTypeTable2',
    default: [['percent'], ['percent'], ['percent']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 2,
    fieldName: 'measurementErrorTable1',
    default: [[1], [1], [1]],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 2,
    fieldName: 'measurementErrorTable2',
    default: [[1], [1], [1]],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 2,
    fieldName: 'measurementErrorThicknessTable1',
    default: [[1], [1], [1]],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 2,
    fieldName: 'measurementErrorThicknessTable2',
    default: [[1], [1], [1]],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 2,
    fieldName: 'measurementErrorPercentSlashThicknessTable1',
    default: [[1], [1], [1]],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 2,
    fieldName: 'measurementErrorPercentSlashThicknessTable2',
    default: [[1], [1], [1]],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'measurementErrorConfidenceTable1',
    default: [['Low'], ['Low'], ['Low']],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'measurementErrorConfidenceTable2',
    default: [['Low'], ['Low'], ['Low']],
    initializer: undefined,
    type: 'input',
  },
  // **********************************************************************************************************
  {
    keyword: 'jacketingWeightingVsOrgFlag',
    valueType: 1,
    fieldName: 'jacketingWeightingVsOrgFlag',
    default: false,
    initializer: undefined,
    type: 'input',
    label: 'Flag for Jacketing Weighting Mode',
    tooltip: 'Flag for Jacketing Weighting Mode',
    transform: (value) => {
      if(value === true || value === 'true') {
        return 1
      }
      return 0
    },
    inverseTransform: (value) => {
      if(value === 1) {
        return true
      }
      return false
    },
  },
  {
    keyword: 'internalDamageRateBool',
    valueType: 1,
    fieldName: 'internalDamageRateBool',
    default: false,
    initializer: undefined,
    type: 'input',
    label: 'Flag to use internal damage rate',
    tooltip: 'Flag to use internal damage rate',
    transform: (value) => {
      if(value === true || value === 'true') {
        return 1
      }
      return 0
    },
    inverseTransform: (value) => {
      if(value === 1) {
        return true
      }
      return false
    },
  },
  {
    keyword: 'runQualMethodsBool',
    valueType: 1,
    fieldName: 'runQualMethodsBool',
    default: false,
    initializer: undefined,
    type: 'input',
    label: 'Flag to run qualitative methods',
    tooltip: 'Flag to run qualitative methods',
    transform: (value) => {
      if(value === true || value === 'true') {
        return 1
      }
      return 0
    },
    inverseTransform: (value) => {
      if(value === 1) {
        return true
      }
      return false
    },
  },
  {
    keyword: 'includeClientMethods',
    valueType: 1,
    fieldName: 'includeClientMethods',
    default: true,
    initializer: undefined,
    type: 'input',
    label: 'Flag to run client methods',
    tooltip: 'Flag to run client methods',
    transform: (value) => {
      if(value === true || value === 'true') {
        return 1
      }
      return 0
    },
    inverseTransform: (value) => {
      if(value === 1) {
        return true
      }
      return false
    },
  },
  {
    keyword: 'includeJacketCoatingExpertPriors',
    valueType: 1,
    fieldName: 'includeJacketCoatingExpertPriors',
    default: false,
    initializer: undefined,
    type: 'input',
    label: 'Flag to include expert priors',
    tooltip: 'Flag to include expert priors',
    transform: (value) => {
      if(value === true || value === 'true') {
        return 1
      }
      return 0
    },
    inverseTransform: (value) => {
      if(value === 1) {
        return true
      }
      return false
    },
  },
  {
    keyword: 'includeDamageRateExpertPriors',
    valueType: 1,
    fieldName: 'includeDamageRateExpertPriors',
    default: false,
    initializer: undefined,
    type: 'input',
    label: 'Flag to include expert priors',
    tooltip: 'Flag to include expert priors',
    transform: (value) => {
      if(value === true || value === 'true') {
        return 1
      }
      return 0
    },
    inverseTransform: (value) => {
      if(value === 1) {
        return true
      }
      return false
    },
  },
  {
    keyword: 'targtetMINPOFBefore',
    valueType: 2,
    fieldName: 'targtetMINPOFBefore',
    min: 0,
    max: 1,
    default: 1e-3,
    initializer: undefined,
    type: 'input',
    label: 'Target Min POF',
  },
  {
    keyword: 'targtetMINPOF',
    valueType: 2,
    fieldName: 'targtetMINPOF',
    min: 0,
    max: 1,
    default: 1e-3,
    initializer: undefined,
    type: 'input',
    label: 'Target Min POD',
  },
  {
    keyword: 'normalOperatingTemp',
    valueType: 2,
    fieldName: 'normalOperatingTemp',
    min: 0,
    default: 70,
    initializer: undefined,
    type: 'input',
    label: 'Normal Operating Temperature',
  },
  {
    keyword: 'normalOperatingTempCyclic',
    valueType: 0,
    fieldName: 'normalOperatingTempCyclic',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Is Normal Operating Temperature Cyclic?',
  },
  {
    keyword: 'componentAge',
    valueType: 2,
    fieldName: 'componentAge',
    min: 0,
    default: 20,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'input',
    label: 'Component Age',
  },
  {
    keyword: 'coatingAge',
    valueType: 2,
    fieldName: 'coatingAge',
    min: 0,
    default: 20,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'input',
    label: 'Coating Age',
  },
  {
    keyword: 'coatingConditionVTE',
    valueType: 0,
    fieldName: 'coatingConditionVTE',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'VTE Coating Condition?',
  },
  {
    keyword: 'coatingConditionVTI',
    valueType: 0,
    fieldName: 'coatingConditionVTI',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'VTI Coating Condition?',
  },
  {
    keyword: 'coatingConditionNDE',
    valueType: 0,
    fieldName: 'coatingConditionNDE',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'NDE Coating Condition?',
  },
  {
    keyword: 'insulationInsectionProgramQuality',
    valueType: 0,
    fieldName: 'insulationInsectionProgramQuality',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Insulation Insection Program Quality?',
  },
  {
    keyword: 'insulationMaintanceInsectionQuality',
    valueType: 0,
    fieldName: 'insulationMaintanceInsectionQuality',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Insulation Maintance Insection Quality?',
  },
  {
    keyword: 'insulationAge',
    valueType: 2,
    fieldName: 'insulationAge',
    min: 0,
    default: 20,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'input',
    label: 'Insulation System Age',
  },
  {
    keyword: 'stressState',
    valueType: 0,
    fieldName: 'stressState',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Stress State',
  },
  {
    keyword: 'acidicEnvironment',
    valueType: 0,
    fieldName: 'acidicEnvironment',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Acidic Environment',
  },
  {
    keyword: 'materialOfConstruction',
    valueType: 0,
    fieldName: 'materialOfConstruction',
    default: 'CS',
    initializer: undefined,
    type: 'input',
    label: 'Material of Construction',
  },
  {
    keyword: 'materialOfConstructionHybrid',
    valueType: 0,
    fieldName: 'materialOfConstructionHybrid',
    default: 'CS',
    initializer: undefined,
    type: 'input',
    label: 'Material of Construction',
  },
  {
    keyword: 'ndeCrackSeverity',
    valueType: 0,
    fieldName: 'ndeCrackSeverity',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'NDE Crack Severity',
  },
  {
    keyword: 'criticalFlawDepthVarType',
    valueType: 0,
    fieldName: 'criticalFlawDepthVarType',
    default: 'sd',
    initializer: undefined,
    type: 'input',
    label: 'Critical Flaw Depth Type',
  },
  {
    keyword: 'criticalFlawDepthConfidence',
    valueType: 0,
    fieldName: 'criticalFlawDepthConfidence',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Critical Flaw Depth Confidence',
  },
  {
    keyword: 'criticalFlawDepth',
    valueType: 2,
    fieldName: 'criticalFlawDepth',
    min: 0,
    default: { USCustomary: 0.5, Metric: 12.7 },
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'input',
    label: 'Critical Flaw Depth',
  },
  {
    keyword: 'measuredFlawDepth',
    valueType: 2,
    fieldName: 'measuredFlawDepth',
    min: 0,
    default: { USCustomary: 0.5, Metric: 12.7 },
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'input',
    label: 'Measured Flaw Depth',
  },
  {
    keyword: 'criticalFlawDepthSd',
    valueType: 2,
    fieldName: 'criticalFlawDepthSd',
    min: 0,
    default: { USCustomary: 0.1, Metric: 2.54 },
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'input',
    label: 'Critical Flaw Depth SD',
  },
  {
    keyword: 'criticalFlawDepthCv',
    valueType: 2,
    fieldName: 'criticalFlawDepthCv',
    min: 0,
    default: { USCustomary: 0.1, Metric: 2.54 },
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'input',
    label: 'Critical Flaw Depth CV',
  },
  // ********************************************** NEW *******************************************************
  {
    keyword: 'problemType',
    valueType: 0,
    default: 'PROB3',
    fieldName: 'problemType',
    initializer: undefined,
    type: 'input',
    label: 'Problem Type',
  },
  {
    keyword: 'dewPointYearlyFractionConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'dewPointYearlyFractionConfidence',
    initializer: undefined,
    type: 'input',
    label: '',
  },
  {
    keyword: 'tempConfOrCV',
    valueType: 0,
    default: 'Confidence',
    fieldName: 'tempConfOrCV',
    initializer: undefined,
    type: 'input',
    label: 'Temperature Confidence or CV?',
  },
  {
    keyword: 'spacingTypeForBoundedVariables',
    valueType: 0,
    default: 'Linear',
    fieldName: 'spacingTypeForBoundedVariables',
    initializer: undefined,
    type: 'input',
    label: 'Bounded Variable Spacing Type',
  },
  {
    keyword: 'operatingTemperatureCV',
    valueType: 2,
    fieldName: 'operatingTemperatureCV',
    min: 0,
    default: 0.1,
    initializer: undefined,
    type: 'input',
    label: 'Operating Temperature CV',
  },
  {
    keyword: 'operatingTemperatureSD',
    valueType: 2,
    fieldName: 'operatingTemperatureSD',
    min: 0,
    default: { USCustomary: 10.0, Metric: 5.0 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'Operating Temperature SD',
  },
  {
    keyword: 'steamVentExposure',
    valueType: 0,
    default: 'unknown',
    fieldName: 'steamVentExposure',
    initializer: undefined,
    type: 'input',
    label: 'Steam Vent Exposure',
  },
  {
    keyword: 'otherDirectExposure',
    valueType: 0,
    default: 'unknown',
    fieldName: 'otherDirectExposure',
    initializer: undefined,
    type: 'input',
    label: 'Other Direct Exposure',
  },
  {
    keyword: 'expertExternalExposureFractionConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'expertExternalExposureFractionConfidence',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertInstantaneousCorrosionRateConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'expertInstantaneousCorrosionRateConfidence',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'modelConfidenceForExternalExposureFraction',
    valueType: 0,
    default: 'Low',
    fieldName: 'modelConfidenceForExternalExposureFraction',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'modelConfidenceForInstantaneousCorrosionRate',
    valueType: 0,
    default: 'Low',
    fieldName: 'modelConfidenceForInstantaneousCorrosionRate',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertCoatingFirstFailureTimeConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'expertCoatingFirstFailureTimeConfidence',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertCoatingDamageRateConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'expertCoatingDamageRateConfidence',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertSealantDamageRateConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'expertSealantDamageRateConfidence',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertSealantFailureTimeConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'expertSealantFailureTimeConfidence',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'modelConfidenceForSealantDamageRateAndSealantFailureTime',
    valueType: 0,
    default: 'Low',
    fieldName: 'modelConfidenceForSealantDamageRateAndSealantFailureTime',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'modelConfidenceForSealantFailureTime',
    valueType: 0,
    default: 'Low',
    fieldName: 'modelConfidenceForSealantFailureTime',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'modelConfidenceForUpdatedMissingJacketFailureTime',
    valueType: 0,
    default: 'Low',
    fieldName: 'modelConfidenceForUpdatedMissingJacketFailureTime',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'modelConfidenceForBaseCoatingDamageAndCoatingFirstFailureTime',
    valueType: 0,
    default: 'Low',
    fieldName: 'modelConfidenceForBaseCoatingDamageAndCoatingFirstFailureTime',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertMissingFailureTimeConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'expertMissingFailureTimeConfidence',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'modelConfidenceForCorrosionInitialFailureTime',
    valueType: 0,
    default: 'Low',
    fieldName: 'modelConfidenceForCorrosionInitialFailureTime',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertJacketDamageRateConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'expertJacketDamageRateConfidence',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertCorrosionFailureConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'expertCorrosionFailureConfidence',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'modelConfidenceForBaseCoatingDamageAndCoatingFirstFailureTime',
    valueType: 0,
    default: 'Low',
    fieldName: 'modelConfidenceForBaseCoatingDamageAndCoatingFirstFailureTime',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'modelConfidenceForEffectiveCorrosionRate',
    valueType: 0,
    default: 'Low',
    fieldName: 'modelConfidenceForEffectiveCorrosionRate',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'modelConfidenceForUpdatedMissingJacketFailureTime',
    valueType: 0,
    default: 'Low',
    fieldName: 'modelConfidenceForUpdatedMissingJacketFailureTime',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'modelConfidenceForCorrosionInitialFailureTime',
    valueType: 0,
    default: 'Low',
    fieldName: 'modelConfidenceForCorrosionInitialFailureTime',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'modelConfidenceForEffectiveCorrosionRate',
    valueType: 0,
    default: 'Low',
    fieldName: 'modelConfidenceForEffectiveCorrosionRate',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertJacketDamageRateConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'expertJacketDamageRateConfidence',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertCorrosionFailureConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'expertCorrosionFailureConfidence',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertEffectiveCorrosionRateConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'expertEffectiveCorrosionRateConfidence',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertMissingFailureTimeConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'expertMissingFailureTimeConfidence',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertEffectiveCorrosionRateConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'expertEffectiveCorrosionRateConfidence',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertEffectiveCorrosionRateMean',
    valueType: 2,
    min: 0,
    default: { USCustomary: 10.0, Metric: 0.25 },
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    fieldName: 'expertEffectiveCorrosionRateMean',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertJacketDamageRateMean',
    valueType: 2,
    min: 0,
    default: 20,
    units: {
      USCustomary: '%/yr',
      Metric: '%/yr',
    },
    fieldName: 'expertJacketDamageRateMean',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertCorrosionFailureMean',
    valueType: 2,
    min: 0,
    default: 5,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    fieldName: 'expertCorrosionFailureMean',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertMissingFailureTimeMean',
    valueType: 2,
    min: 0,
    default: 5,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    fieldName: 'expertMissingFailureTimeMean',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertSealantFailureTimeMean',
    valueType: 2,
    min: 0,
    default: 5,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    fieldName: 'expertSealantFailureTimeMean',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertSealantDamageRateMean',
    valueType: 2,
    min: 0,
    default: 20,
    units: {
      USCustomary: '%/yr',
      Metric: '%/yr',
    },
    fieldName: 'expertSealantDamageRateMean',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertCoatingDamageRateMean',
    valueType: 2,
    min: 0,
    default: 20,
    units: {
      USCustomary: '%/yr',
      Metric: '%/yr',
    },
    fieldName: 'expertCoatingDamageRateMean',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertCoatingFirstFailureTimeMean',
    valueType: 2,
    min: 0,
    default: 5,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    fieldName: 'expertCoatingFirstFailureTimeMean',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertInstantaneousCorrosionRateMean',
    valueType: 2,
    min: 0,
    default: { USCustomary: 20.0, Metric: 0.25 },
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    fieldName: 'expertInstantaneousCorrosionRateMean',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertExternalExposureFractionMean',
    valueType: 2,
    min: 0,
    default: 50,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    fieldName: 'expertExternalExposureFractionMean',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertMissingFailureTimeMean',
    valueType: 2,
    min: 0,
    default: 5,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    fieldName: 'expertMissingFailureTimeMean',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertCorrosionFailureMean',
    valueType: 2,
    min: 0,
    default: 5,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    fieldName: 'expertCorrosionFailureMean',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'expertJacketDamageRateMean',
    valueType: 2,
    min: 0,
    default: 20,
    units: {
      USCustomary: '%/yr',
      Metric: '%/yr',
    },
    fieldName: 'expertJacketDamageRateMean',
    initializer: undefined,
    type: 'input',
    label: 'The',
  },
  {
    keyword: 'sealantAmount',
    valueType: 0,
    default: 'unknown',
    fieldName: 'sealantAmount',
    initializer: undefined,
    type: 'input',
    label: 'Sealant Amount',
  },
  {
    keyword: 'elevation',
    valueType: 0,
    default: 'unknown',
    fieldName: 'elevation',
    initializer: undefined,
    type: 'input',
    label: 'Elevation',
  },
  {
    keyword: 'windSpeedFraction1',
    valueType: 0,
    default: 'unknown',
    fieldName: 'windSpeedFraction1',
    initializer: undefined,
    type: 'input',
    label: 'Wind Speed Fraction',
  },
  // {
  //   keyword: 'externalEnvironment',
  //   valueType: 0,
  //   default: 'unknown',
  //   fieldName: 'externalEnvironment',
  //   initializer: undefined,
  //   type: 'input',
  //   label: 'External Environment',
  // },
  {
    keyword: 'internalPh',
    valueType: 0,
    default: 'unknown',
    fieldName: 'internalPh',
    initializer: undefined,
    type: 'input',
    label: 'Internal pH',
  },
  {
    keyword: 'internalExposureFractionConfidence',
    valueType: 0,
    default: 'Low',
    fieldName: 'internalExposureFractionConfidence',
    initializer: undefined,
    type: 'input',
    label: 'Internal Exposure Fraction Confidence',
  },
  {
    keyword: 'moistureAccumulationSites',
    valueType: 0,
    default: 'unknown',
    fieldName: 'moistureAccumulationSites',
    initializer: undefined,
    type: 'input',
    label: 'Moisture Accumulation Sites',
  },
  {
    keyword: 'pastMaintenanceTime',
    valueType: 2,
    min: 0,
    default: 5,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    fieldName: 'pastMaintenanceTime',
    initializer: undefined,
    type: 'input',
    label: 'Past Maintenance Time',
  },
  {
    keyword: 'componentReplacedTime',
    valueType: 2,
    min: 0,
    default: 5,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    fieldName: 'componentReplacedTime',
    initializer: undefined,
    type: 'input',
    label: 'Component Replaced Time',
  },
  {
    keyword: 'jacketingMissingReplacedTime',
    valueType: 2,
    min: 0,
    default: 5,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    fieldName: 'jacketingMissingReplacedTime',
    initializer: undefined,
    type: 'input',
    label: 'Jacketing Missing Replaced Time',
  },
  {
    keyword: 'vaporBarrierReplacedTime',
    valueType: 2,
    min: 0,
    default: 5,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    fieldName: 'vaporBarrierReplacedTime',
    initializer: undefined,
    type: 'input',
    label: 'Vapor Barrier Replaced Time',
  },
  {
    keyword: 'vaporBarrierSealantReplacedTime',
    valueType: 2,
    min: 0,
    default: 5,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    fieldName: 'vaporBarrierSealantReplacedTime',
    initializer: undefined,
    type: 'input',
    label: 'Vapor Barrier Sealant Replaced Time',
  },
  {
    keyword: 'jacketingSealantReplacedTime',
    valueType: 2,
    min: 0,
    default: 5,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    fieldName: 'jacketingSealantReplacedTime',
    initializer: undefined,
    type: 'input',
    label: 'Jacketing Sealant Replaced Time',
  },
  {
    keyword: 'jacketingReplacedTime',
    valueType: 2,
    min: 0,
    default: 5,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    fieldName: 'jacketingReplacedTime',
    initializer: undefined,
    type: 'input',
    label: 'Jacketing Replaced Time',
  },
  {
    keyword: 'coatingReplacedTime',
    valueType: 2,
    min: 0,
    default: 5,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    fieldName: 'coatingReplacedTime',
    initializer: undefined,
    type: 'input',
    label: 'Coating Replaced Time',
  },
  {
    keyword: 'jacketingSealantReplaced',
    valueType: 0,
    default: 'No',
    fieldName: 'jacketingSealantReplaced',
    initializer: undefined,
    type: 'input',
    label: 'Jacketing Sealant Replaced',
  },
  {
    keyword: 'componentReplaced',
    valueType: 0,
    default: 'No',
    fieldName: 'componentReplaced',
    initializer: undefined,
    type: 'input',
    label: 'Component Replaced',
  },
  {
    keyword: 'jacketingReplaced',
    valueType: 0,
    default: 'No',
    fieldName: 'jacketingReplaced',
    initializer: undefined,
    type: 'input',
    label: 'Jacketing Replaced',
  },
  {
    keyword: 'coatingReplaced',
    valueType: 0,
    default: 'No',
    fieldName: 'coatingReplaced',
    initializer: undefined,
    type: 'input',
    label: 'Coating Replaced',
  },
  {
    keyword: 'componentAgeVsInstallDateBool',
    valueType: 1,
    fieldName: 'componentAgeVsInstallDateBool',
    default: false,
    initializer: undefined,
    type: 'input',
    label: 'Component Age vs Install Date Bool',
    tooltip: 'Component Age vs Install Date Bool',
    transform: (value) => {
      if(value === true || value === 'true') {
        return 1
      }
      return 0
    },
    inverseTransform: (value) => {
      if(value === 1) {
        return true
      }
      return false
    },
  },
  {
    keyword: 'runCondensation',
    valueType: 1,
    fieldName: 'runCondensation',
    default: true,
    initializer: undefined,
    type: 'input',
    label: 'Flag to run Condensation Networks',
    tooltip: 'Flag to run Condensation Networks',
    transform: (value) => {
      if(value === true || value === 'true') {
        return 1
      }
      return 0
    },
    inverseTransform: (value) => {
      if(value === 1) {
        return true
      }
      return false
    },
  },
  {
    keyword: 'temperatureSpring',
    valueType: 2,
    fieldName: 'temperatureSpring',
    default: { USCustomary: 70.0, Metric: 21 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'Spring Temperature',
  },
  {
    keyword: 'temperatureSummer',
    valueType: 2,
    fieldName: 'temperatureSummer',
    default: { USCustomary: 70.0, Metric: 21 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'Summer Temperature',
  },
  {
    keyword: 'temperatureFall',
    valueType: 2,
    fieldName: 'temperatureFall',
    default: { USCustomary: 70.0, Metric: 21 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'Fall Temperature',
  },
  {
    keyword: 'temperatureWinter',
    valueType: 2,
    fieldName: 'temperatureWinter',
    default: { USCustomary: 70.0, Metric: 21 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'Winter Temperature',
  },
  {
    keyword: 'pipeTemperature',
    valueType: 2,
    fieldName: 'pipeTemperature',
    default: { USCustomary: 70.0, Metric: 21 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'Pipe Temperature',
  },
  {
    keyword: 'relativeHumidity',
    valueType: 2,
    fieldName: 'relativeHumidity',
    min: 0,
    max: 100,
    default: 50,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'input',
    label: 'Relative Humidity',
  },
  {
    keyword: 'seamsAmount',
    valueType: 0,
    fieldName: 'seamsAmount',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Seams Amount',
  },
  {
    keyword: 'properSeamAdhesian',
    valueType: 0,
    fieldName: 'properSeamAdhesian',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Proper Seam Adhesian',
  },
  {
    keyword: 'modelConfidenceForVaporBarrierDamageRateAndVaporBarrierFailureTime',
    valueType: 0,
    fieldName: 'modelConfidenceForVaporBarrierDamageRateAndVaporBarrierFailureTime',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Model Confidence for Vapor Barrier Damage Rate and Failure Time',
  },
  {
    keyword: 'expertVaporBarrierFailureTimeConfidence',
    valueType: 0,
    fieldName: 'expertVaporBarrierFailureTimeConfidence',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Expert Sealant Vapor Barrier Failure Time Confidence',
  },
  {
    keyword: 'expertVaporBarrierDamageRate',
    valueType: 2,
    fieldName: 'expertVaporBarrierDamageRate',
    min: 0,
    default: 20,
    initializer: undefined,
    units: {
      USCustomary: '%/yr',
      Metric: '%/yr',
    },
    type: 'input',
    label: 'Expert Sealant Vapor Barrier Damage Rate Mean',
  },
  {
    keyword: 'expertVaporBarrierFailureTimeMean',
    valueType: 2,
    fieldName: 'expertVaporBarrierFailureTimeMean',
    min: 0,
    default: 5,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'input',
    label: 'Expert Sealant Vapor Barrier Damage Rate Mean',
  },
  {
    keyword: 'modelConfidenceForMissingVaporBarrierFailureTime',
    valueType: 0,
    fieldName: 'modelConfidenceForMissingVaporBarrierFailureTime',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Model Confidence for Missing Vapor Barrier Failure Time',
  },
  {
    keyword: 'expertVaporBarrierDamageRateConfidence',
    valueType: 0,
    fieldName: 'expertVaporBarrierDamageRateConfidence',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Expert Sealant Vapor Barrier Damage Rate Confidence',
  },
  {
    keyword: 'expertMissingVaporBarrierFailureTimeConfidence',
    valueType: 0,
    fieldName: 'expertMissingVaporBarrierFailureTimeConfidence',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Expert Missing Vapor Barrier Failure Time Confidence',
  },
  {
    keyword: 'expertMissingVaporBarrierFailureTimeMean',
    valueType: 2,
    fieldName: 'expertMissingVaporBarrierFailureTimeMean',
    min: 0,
    default: 5,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'input',
    label: 'Expert Missing Vapor Barrier Failure Time Mean',
  },
  // **********************************************************************************************************
  // **********************************************************************************************************
  //                                                OUTPUT
  // **********************************************************************************************************
  // **********************************************************************************************************
  {
    keyword: 'manyTableInspectionPlottingBool',
    valueType: 0,
    fieldName: 'manyTableInspectionPlottingBool',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'beforeOrAfterInspections',
    valueType: 0,
    fieldName: 'beforeOrAfterInspections',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'beforeOrAfterInspectionsPlot',
    valueType: 0,
    fieldName: 'beforeOrAfterInspectionsPlot',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'whichTable',
    valueType: 0,
    fieldName: 'whichTable',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'whichNetwork',
    valueType: 0,
    fieldName: 'whichNetwork',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'returnCondensationBool',
    valueType: 2,
    fieldName: 'returnCondensationBool',
    default: 0,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'whichNetworkQual',
    valueType: 0,
    fieldName: 'whichNetworkQual',
    default: '1',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutProblemType1Condensation01',
    valueType: 0,
    fieldName: 'poppedJsonOutProblemType1Condensation01',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutProblemType1Condensation02',
    valueType: 0,
    fieldName: 'poppedJsonOutProblemType1Condensation02',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutActualThicknessProb_1',
    valueType: 0,
    fieldName: 'poppedJsonOutActualThicknessProb_1',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutActualThicknessProb_2',
    valueType: 0,
    fieldName: 'poppedJsonOutActualThicknessProb_2',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutActualThicknessProb_3',
    valueType: 0,
    fieldName: 'poppedJsonOutActualThicknessProb_3',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutExposureFraction',
    valueType: 0,
    fieldName: 'poppedJsonOutExposureFraction',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutDamageRate',
    valueType: 0,
    fieldName: 'poppedJsonOutDamageRate',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutJacketingFailureTime',
    valueType: 0,
    fieldName: 'poppedJsonOutJacketingFailureTime',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutCoatingFailureTime',
    valueType: 0,
    fieldName: 'poppedJsonOutCoatingFailureTime',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutComponentFailureTime',
    valueType: 0,
    fieldName: 'poppedJsonOutComponentFailureTime',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutMaintenanceStrat01',
    valueType: 0,
    fieldName: 'poppedJsonOutMaintenanceStrat01',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutMaintenanceStrat02',
    valueType: 0,
    fieldName: 'poppedJsonOutMaintenanceStrat02',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutMaintenanceStrat03',
    valueType: 0,
    fieldName: 'poppedJsonOutMaintenanceStrat03',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutMaintenanceStrat04',
    valueType: 0,
    fieldName: 'poppedJsonOutMaintenanceStrat04',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutMaintenanceStrat05',
    valueType: 0,
    fieldName: 'poppedJsonOutMaintenanceStrat05',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutReplaceDecision',
    valueType: 0,
    fieldName: 'poppedJsonOutReplaceDecision',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonQualMethod_API581',
    valueType: 0,
    fieldName: 'poppedJsonQualMethod_API581',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonQualMethod_BPMethod',
    valueType: 0,
    fieldName: 'poppedJsonQualMethod_BPMethod',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonQualMethod_DNVGLMethod',
    valueType: 0,
    fieldName: 'poppedJsonQualMethod_DNVGLMethod',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonQualMethod_IneosMethod',
    valueType: 0,
    fieldName: 'poppedJsonQualMethod_IneosMethod',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonQualMethod_McVayMethod',
    valueType: 0,
    fieldName: 'poppedJsonQualMethod_McVayMethod',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonQualMethod_API583',
    valueType: 0,
    fieldName: 'poppedJsonQualMethod_API583',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonQualMethod_EuroMethod',
    valueType: 0,
    fieldName: 'poppedJsonQualMethod_EuroMethod',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutProblemType2_012',
    valueType: 0,
    fieldName: 'poppedJsonOutProblemType2_012',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutProblemType2_034',
    valueType: 0,
    fieldName: 'poppedJsonOutProblemType2_034',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutProblemType2_05',
    valueType: 0,
    fieldName: 'poppedJsonOutProblemType2_05',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutProblemType2_06',
    valueType: 0,
    fieldName: 'poppedJsonOutProblemType2_06',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutProblemType2_07',
    valueType: 0,
    fieldName: 'poppedJsonOutProblemType2_07',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  // {
  //   keyword: 'poppedJsonOutProblemType2_08',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutProblemType2_08',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  {
    keyword: 'poppedJsonOutProblemType2_09',
    valueType: 0,
    fieldName: 'poppedJsonOutProblemType2_09',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutProblemType2_10',
    valueType: 0,
    fieldName: 'poppedJsonOutProblemType2_10',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutProblemType2_11',
    valueType: 0,
    fieldName: 'poppedJsonOutProblemType2_11',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutProblemType2_12',
    valueType: 0,
    fieldName: 'poppedJsonOutProblemType2_12',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutProblemType2_13',
    valueType: 0,
    fieldName: 'poppedJsonOutProblemType2_13',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutProblemType2_14',
    valueType: 0,
    fieldName: 'poppedJsonOutProblemType2_14',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutProblemType2_15',
    valueType: 0,
    fieldName: 'poppedJsonOutProblemType2_15',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutProblemType2_16',
    valueType: 0,
    fieldName: 'poppedJsonOutProblemType2_16',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutProblemType2_17',
    valueType: 0,
    fieldName: 'poppedJsonOutProblemType2_17',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutProblemType2_18',
    valueType: 0,
    fieldName: 'poppedJsonOutProblemType2_18',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutActualThicknessProb2_11_1',
    valueType: 0,
    fieldName: 'poppedJsonOutActualThicknessProb2_11_1',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutActualThicknessProb2_11_2',
    valueType: 0,
    fieldName: 'poppedJsonOutActualThicknessProb2_11_2',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutActualThicknessProb2_11_3',
    valueType: 0,
    fieldName: 'poppedJsonOutActualThicknessProb2_11_3',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutActualThicknessProb2_14_1',
    valueType: 0,
    fieldName: 'poppedJsonOutActualThicknessProb2_14_1',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutActualThicknessProb2_14_2',
    valueType: 0,
    fieldName: 'poppedJsonOutActualThicknessProb2_14_2',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutActualThicknessProb2_14_3',
    valueType: 0,
    fieldName: 'poppedJsonOutActualThicknessProb2_14_3',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutActualThicknessProb2_17_1',
    valueType: 0,
    fieldName: 'poppedJsonOutActualThicknessProb2_17_1',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutActualThicknessProb2_17_2',
    valueType: 0,
    fieldName: 'poppedJsonOutActualThicknessProb2_17_2',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutActualThicknessProb2_17_3',
    valueType: 0,
    fieldName: 'poppedJsonOutActualThicknessProb2_17_3',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },

  {
    keyword: 'poppedJsonOutProblemType2Condensation19',
    valueType: 0,
    fieldName: 'poppedJsonOutProblemType2Condensation19',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutProblemType2Condensation20',
    valueType: 0,
    fieldName: 'poppedJsonOutProblemType2Condensation20',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutProblemType2Condensation23',
    valueType: 0,
    fieldName: 'poppedJsonOutProblemType2Condensation23',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutProblemType2Condensation12',
    valueType: 0,
    fieldName: 'poppedJsonOutProblemType2Condensation12',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutProblemType2Condensation21',
    valueType: 0,
    fieldName: 'poppedJsonOutProblemType2Condensation21',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutProblemType2Condensation22',
    valueType: 0,
    fieldName: 'poppedJsonOutProblemType2Condensation22',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutProblemType2Condensation24',
    valueType: 0,
    fieldName: 'poppedJsonOutProblemType2Condensation24',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutProblemType2Condensation25',
    valueType: 0,
    fieldName: 'poppedJsonOutProblemType2Condensation25',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutProblemType2Condensation26',
    valueType: 0,
    fieldName: 'poppedJsonOutProblemType2Condensation26',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutProblemType2Condensation27',
    valueType: 0,
    fieldName: 'poppedJsonOutProblemType2Condensation27',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutActualThicknessProb2_CondensationSeams_1',
    valueType: 0,
    fieldName: 'poppedJsonOutActualThicknessProb2_CondensationSeams_1',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutActualThicknessProb2_CondensationSeams_2',
    valueType: 0,
    fieldName: 'poppedJsonOutActualThicknessProb2_CondensationSeams_2',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutActualThicknessProb2_CondensationSeams_3',
    valueType: 0,
    fieldName: 'poppedJsonOutActualThicknessProb2_CondensationSeams_3',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutActualThicknessProb2_MissingVP_1',
    valueType: 0,
    fieldName: 'poppedJsonOutActualThicknessProb2_MissingVP_1',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutActualThicknessProb2_MissingVP_2',
    valueType: 0,
    fieldName: 'poppedJsonOutActualThicknessProb2_MissingVP_2',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutActualThicknessProb2_MissingVP_3',
    valueType: 0,
    fieldName: 'poppedJsonOutActualThicknessProb2_MissingVP_3',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'vaporBarrierFailureTimeEV',
    valueType: 2,
    fieldName: 'vaporBarrierFailureTimeEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Vapor Barrier Failure Time EV',
  },
  {
    keyword: 'vaporBarrierFailureTimeSD',
    valueType: 2,
    fieldName: 'vaporBarrierFailureTimeSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Vapor Barrier Failure Time SD',
  },
  {
    keyword: 'failureTimeEV',
    valueType: 2,
    fieldName: 'failureTimeEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Component Failure Time EV',
    dashboardSortOrder: 'asc', // desc
  },
  {
    keyword: 'failureTimeSD',
    valueType: 2,
    fieldName: 'failureTimeSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Component Failure Time',
  },
  {
    keyword: 'failureTimeEVPrior',
    valueType: 2,
    fieldName: 'failureTimeEVPrior',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Component Failure Time',
  },
  {
    keyword: 'failureTimeSDPrior',
    valueType: 2,
    fieldName: 'failureTimeSDPrior',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Component Failure Time',
  },
  {
    keyword: 'failureVaporBarrierPOF',
    valueType: 2,
    fieldName: 'failureVaporBarrierPOF',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Sealant Vapor Barrier POF at Component Age',
  },
  {
    keyword: 'failureVaporBarrierMissingPOF',
    valueType: 2,
    fieldName: 'failureVaporBarrierMissingPOF',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Vapor Barrier Missing POF at Component Age',
  },
  {
    keyword: 'pofAfter',
    valueType: 2,
    fieldName: 'pofAfter',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Component POF at Component Age',
    dashboardSortOrder: 'desc', // asc
  },
  {
    keyword: 'pofBefore',
    valueType: 2,
    fieldName: 'pofBefore',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Component POF at Component Age',
  },
  {
    keyword: 'pofAfterCoating',
    valueType: 2,
    fieldName: 'pofAfterCoating',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Coating POF at Component Age',
  },
  {
    keyword: 'pofBeforeCoating',
    valueType: 2,
    fieldName: 'pofBeforeCoating',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Coating POF at Component Age',
  },
  {
    keyword: 'pofAfterJacketing',
    valueType: 2,
    fieldName: 'pofAfterJacketing',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Jacketing POF at Component Age',
  },
  {
    keyword: 'pofBeforeJacketing',
    valueType: 2,
    fieldName: 'pofBeforeJacketing',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Jacketing POF at Component Age',
  },
  {
    keyword: 'jacketingMissingFailureTimeEV',
    valueType: 2,
    fieldName: 'jacketingMissingFailureTimeEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Jacketing Missing Failure Time EV',
  },
  {
    keyword: 'jacketingMissingFailureTimeSD',
    valueType: 2,
    fieldName: 'jacketingMissingFailureTimeSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Jacketing Missing Failure Time SD',
  },
  {
    keyword: 'jacketingMissingFailureTimeEVPrior',
    valueType: 2,
    fieldName: 'jacketingMissingFailureTimeEVPrior',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Jacketing Missing Failure Time EV',
  },
  {
    keyword: 'jacketingMissingFailureTimeSDPrior',
    valueType: 2,
    fieldName: 'jacketingMissingFailureTimeSDPrior',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Jacketing Missing Failure Time SD',
  },
  {
    keyword: 'vaporBarrierMissingFailureTimeEV',
    valueType: 2,
    fieldName: 'vaporBarrierMissingFailureTimeEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Vapor Barrier Missing Failure Time EV',
  },
  {
    keyword: 'vaporBarrierSealantFailureTimeEV',
    valueType: 2,
    fieldName: 'vaporBarrierSealantFailureTimeEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Vapor Barrier Sealant Failure Time EV',
  },
  {
    keyword: 'vaporBarrierMissingFailureTimeSD',
    valueType: 2,
    fieldName: 'vaporBarrierMissingFailureTimeSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Vapor Barrier Missing Failure Time SD',
  },
  {
    keyword: 'vaporBarrierSealantFailureTimeSD',
    valueType: 2,
    fieldName: 'vaporBarrierSealantFailureTimeSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Vapor Barrier Sealant Failure Time SD',
  },
  {
    keyword: 'jacketingCorrosionFailureTimeEV',
    valueType: 2,
    fieldName: 'jacketingCorrosionFailureTimeEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: '',
  },
  {
    keyword: 'jacketingCorrosionFailureTimeSD',
    valueType: 2,
    fieldName: 'jacketingCorrosionFailureTimeSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: '',
  },
  {
    keyword: 'jacketingCorrosionFailureTimeEVPrior',
    valueType: 2,
    fieldName: 'jacketingCorrosionFailureTimeEVPrior',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: '',
  },
  {
    keyword: 'jacketingCorrosionFailureTimeSDPrior',
    valueType: 2,
    fieldName: 'jacketingCorrosionFailureTimeSDPrior',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: '',
  },
  {
    keyword: 'jacketingDesignFailureTimeEV',
    valueType: 2,
    fieldName: 'jacketingDesignFailureTimeEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: '',
  },
  {
    keyword: 'jacketingDesignFailureTimeSD',
    valueType: 2,
    fieldName: 'jacketingDesignFailureTimeSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: '',
  },
  {
    keyword: 'jacketingDesignFailureTimeEVPrior',
    valueType: 2,
    fieldName: 'jacketingDesignFailureTimeEVPrior',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: '',
  },
  {
    keyword: 'jacketingDesignFailureTimeSDPrior',
    valueType: 2,
    fieldName: 'jacketingDesignFailureTimeSDPrior',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: '',
  },
  {
    keyword: 'jacketingSealantFailureTimeEV',
    valueType: 2,
    fieldName: 'jacketingSealantFailureTimeEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Jacketing Sealant Failure Time EV',
  },
  {
    keyword: 'jacketingSealantFailureTimeSD',
    valueType: 2,
    fieldName: 'jacketingSealantFailureTimeSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Jacketing Sealant Failure Time SD',
  },
  {
    keyword: 'jacketingSealantFailureTimeEVPrior',
    valueType: 2,
    fieldName: 'jacketingSealantFailureTimeEVPrior',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Jacketing Sealant Failure Time EV',
  },
  {
    keyword: 'jacketingSealantFailureTimeSDPrior',
    valueType: 2,
    fieldName: 'jacketingSealantFailureTimeSDPrior',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Jacketing Sealant Failure Time SD',
  },
  {
    keyword: 'missingVaporBarrierComponentFailureTimeEV',
    valueType: 2,
    fieldName: 'missingVaporBarrierComponentFailureTimeEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Component Missing Vapor Barrier Failure Time EV',
  },
  {
    keyword: 'missingVaporBarrierComponentFailureTimeSD',
    valueType: 2,
    fieldName: 'missingVaporBarrierComponentFailureTimeSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Component Missing Vapor Barrier Failure Time SD',
  },
  {
    keyword: 'condensationJacketingComponentFailureTimeEV',
    valueType: 2,
    fieldName: 'condensationJacketingComponentFailureTimeEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Component Vapor Barrier Sealant Failure Time EV',
  },
  {
    keyword: 'condensationJacketingComponentFailureTimeSD',
    valueType: 2,
    fieldName: 'condensationJacketingComponentFailureTimeSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Component Vapor Barrier Sealant Failure Time SD',
  },
  {
    keyword: 'jacketingPrimaryFailureTimeEV',
    valueType: 2,
    fieldName: 'jacketingPrimaryFailureTimeEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Jacketing Primary Failure Time EV',
  },
  {
    keyword: 'jacketingPrimaryFailureTimeSD',
    valueType: 2,
    fieldName: 'jacketingPrimaryFailureTimeSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Jacketing Primary Failure Time SD',
  },
  {
    keyword: 'actualThicknessAtThatTimeEV',
    valueType: 2,
    fieldName: 'actualThicknessAtThatTimeEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'output',
    label: 'Actual Thickness at Component Age Mean',
  },
  {
    keyword: 'actualThicknessAtThatTimeSD',
    valueType: 2,
    fieldName: 'actualThicknessAtThatTimeSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'output',
    label: 'Actual Thickness at Component Age SD',
  },
  {
    keyword: 'damageRateEV',
    valueType: 2,
    fieldName: 'damageRateEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    type: 'output',
    label: 'Component Damage Rate EV',
    dashboardSortOrder: 'desc', // asc
  },
  {
    keyword: 'damageRateSD',
    valueType: 2,
    fieldName: 'damageRateSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    type: 'output',
    label: 'Component Damage Rate',
  },
  {
    keyword: 'damageRateEVPrior',
    valueType: 2,
    fieldName: 'damageRateEVPrior',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    type: 'output',
    label: 'Component Damage Rate',
  },
  {
    keyword: 'damageRateSDPrior',
    valueType: 2,
    fieldName: 'damageRateSDPrior',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    type: 'output',
    label: 'Component Damage Rate',
  },
  {
    keyword: 'initiationTimeEV',
    valueType: 2,
    fieldName: 'initiationTimeEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Component Damage Initiation Time EV',
    dashboardSortOrder: 'asc', // desc
  },
  {
    keyword: 'initiationTimeSD',
    valueType: 2,
    fieldName: 'initiationTimeSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Component Damage Initiation Time',
  },
  {
    keyword: 'initiationTimeEVPrior',
    valueType: 2,
    fieldName: 'initiationTimeEVPrior',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Component Damage Initiation Time',
  },
  {
    keyword: 'initiationTimeSDPrior',
    valueType: 2,
    fieldName: 'initiationTimeSDPrior',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Component Damage Initiation Time',
  },
  {
    keyword: 'jacketingFailureTimeEV',
    valueType: 2,
    fieldName: 'jacketingFailureTimeEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Jacketing Failure Time EV',
    dashboardSortOrder: 'asc', // desc
  },
  {
    keyword: 'jacketingFailureTimeSD',
    valueType: 2,
    fieldName: 'jacketingFailureTimeSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Jacketing Failure Time',
  },
  {
    keyword: 'jacketingFailureTimeEVPrior',
    valueType: 2,
    fieldName: 'jacketingFailureTimeEVPrior',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Jacketing Failure Time',
  },
  {
    keyword: 'jacketingFailureTimeSDPrior',
    valueType: 2,
    fieldName: 'jacketingFailureTimeSDPrior',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Jacketing Failure Time',
  },
  {
    keyword: 'coatingFailureTimeEV',
    valueType: 2,
    fieldName: 'coatingFailureTimeEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Coating Failure Time EV',
    dashboardSortOrder: 'asc', // desc
  },
  {
    keyword: 'coatingFailureTimeSD',
    valueType: 2,
    fieldName: 'coatingFailureTimeSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Coating Failure Time',
  },
  {
    keyword: 'coatingFailureTimeEVPrior',
    valueType: 2,
    fieldName: 'coatingFailureTimeEVPrior',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Coating Failure Time',
  },
  {
    keyword: 'coatingFailureTimeSDPrior',
    valueType: 2,
    fieldName: 'coatingFailureTimeSDPrior',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Coating Failure Time',
  },
  {
    keyword: 'startingThicknessEV',
    valueType: 2,
    fieldName: 'startingThicknessEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'output',
    label: 'Starting Thickness',
  },
  {
    keyword: 'startingThicknessSD',
    valueType: 2,
    fieldName: 'startingThicknessSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'output',
    label: 'Starting Thickness',
  },
  {
    keyword: 'timeMax',
    valueType: 2,
    fieldName: 'timeMax',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'whichManyTablePlot',
    valueType: 0,
    fieldName: 'whichManyTablePlot',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableLower_01BoundJsonMissingJacketing',
    valueType: 0,
    fieldName: 'multTableLower_01BoundJsonMissingJacketing',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableLower_1BoundJsonMissingJacketing',
    valueType: 0,
    fieldName: 'multTableLower_1BoundJsonMissingJacketing',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableLowerBoundJsonMissingJacketing',
    valueType: 0,
    fieldName: 'multTableLowerBoundJsonMissingJacketing',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableLower_01BoundJsonSealantJacketing',
    valueType: 0,
    fieldName: 'multTableLower_01BoundJsonSealantJacketing',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableLower_1BoundJsonSealantJacketing',
    valueType: 0,
    fieldName: 'multTableLower_1BoundJsonSealantJacketing',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableLowerBoundJsonSealantJacketing',
    valueType: 0,
    fieldName: 'multTableLowerBoundJsonSealantJacketing',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableLower_01BoundJsonCorrosionJacketing',
    valueType: 0,
    fieldName: 'multTableLower_01BoundJsonCorrosionJacketing',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableLower_1BoundJsonCorrosionJacketing',
    valueType: 0,
    fieldName: 'multTableLower_1BoundJsonCorrosionJacketing',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableLowerBoundJsonCorrosionJacketing',
    valueType: 0,
    fieldName: 'multTableLowerBoundJsonCorrosionJacketing',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableLower_01BoundJsonMissingVaporBarrier',
    valueType: 0,
    fieldName: 'multTableLower_01BoundJsonMissingVaporBarrier',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableLower_1BoundJsonMissingVaporBarrier',
    valueType: 0,
    fieldName: 'multTableLower_1BoundJsonMissingVaporBarrier',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableLowerBoundJsonMissingVaporBarrier',
    valueType: 0,
    fieldName: 'multTableLowerBoundJsonMissingVaporBarrier',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableLower_01BoundJsonSealantVaporBarrier',
    valueType: 0,
    fieldName: 'multTableLower_01BoundJsonSealantVaporBarrier',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableLower_1BoundJsonSealantVaporBarrier',
    valueType: 0,
    fieldName: 'multTableLower_1BoundJsonSealantVaporBarrier',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableLowerBoundJsonSealantVaporBarrier',
    valueType: 0,
    fieldName: 'multTableLowerBoundJsonSealantVaporBarrier',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableMedianJsonMissingJacketing',
    valueType: 0,
    fieldName: 'multTableMedianJsonMissingJacketing',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableMedianJsonSealantJacketing',
    valueType: 0,
    fieldName: 'multTableMedianJsonSealantJacketing',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableMedianJsonCorrosionJacketing',
    valueType: 0,
    fieldName: 'multTableMedianJsonCorrosionJacketing',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableMedianJsonMissingVaporBarrier',
    valueType: 0,
    fieldName: 'multTableMedianJsonMissingVaporBarrier',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableMedianJsonSealantVaporBarrier',
    valueType: 0,
    fieldName: 'multTableMedianJsonSealantVaporBarrier',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableUpperBoundJsonMissingJacketing',
    valueType: 0,
    fieldName: 'multTableUpperBoundJsonMissingJacketing',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableUpperBoundJsonSealantJacketing',
    valueType: 0,
    fieldName: 'multTableUpperBoundJsonSealantJacketing',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableUpperBoundJsonCorrosionJacketing',
    valueType: 0,
    fieldName: 'multTableUpperBoundJsonCorrosionJacketing',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableUpperBoundJsonMissingVaporBarrier',
    valueType: 0,
    fieldName: 'multTableUpperBoundJsonMissingVaporBarrier',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableUpperBoundJsonSealantVaporBarrier',
    valueType: 0,
    fieldName: 'multTableUpperBoundJsonSealantVaporBarrier',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutAll_Labels',
    valueType: 0,
    fieldName: 'poppedJsonOutAll_Labels',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutAll_LabelsBefore',
    valueType: 0,
    fieldName: 'poppedJsonOutAll_LabelsBefore',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutsAll',
    valueType: 0,
    fieldName: 'poppedJsonOutsAll',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutsAllBefore',
    valueType: 0,
    fieldName: 'poppedJsonOutsAllBefore',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'cdfTraceJson',
    valueType: 0,
    fieldName: 'cdfTraceJson',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'cdfTimeJson',
    valueType: 0,
    fieldName: 'cdfTimeJson',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'cdfTimeMiMaxJson',
    valueType: 0,
    fieldName: 'cdfTimeMiMaxJson',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableLowerBound_01Json',
    valueType: 0,
    fieldName: 'multTableLowerBound_01Json',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableLowerBound_1Json',
    valueType: 0,
    fieldName: 'multTableLowerBound_1Json',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableLowerBoundJson',
    valueType: 0,
    fieldName: 'multTableLowerBoundJson',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableMedianJson',
    valueType: 0,
    fieldName: 'multTableMedianJson',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableUpperBoundJson',
    valueType: 0,
    fieldName: 'multTableUpperBoundJson',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'multTableUpperBound_99Json',
    valueType: 0,
    fieldName: 'multTableUpperBound_99Json',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'timeMultTablesJson',
    valueType: 0,
    fieldName: 'timeMultTablesJson',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'tMinMultTablesJson',
    valueType: 0,
    fieldName: 'tMinMultTablesJson',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'tMinMultTablesMissingJson',
    valueType: 0,
    fieldName: 'tMinMultTablesMissingJson',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'tMinMultTablesSealantJson',
    valueType: 0,
    fieldName: 'tMinMultTablesSealantJson',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'tMinMultTablesCorrosionJson',
    valueType: 0,
    fieldName: 'tMinMultTablesCorrosionJson',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'tMinMultTablesSealantVaporBarrierJson',
    valueType: 0,
    fieldName: 'tMinMultTablesSealantVaporBarrierJson',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'tMinMultTablesMissingVaporBarrierJson',
    valueType: 0,
    fieldName: 'tMinMultTablesMissingVaporBarrierJson',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'timeMultTablesMax',
    valueType: 2,
    fieldName: 'timeMultTablesMax',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'expectedUtilities_1',
    valueType: 2,
    fieldName: 'expectedUtilities_1',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Expected Utilities?',
  },
  {
    keyword: 'replacementTimes_1',
    valueType: 2,
    fieldName: 'replacementTimes_1',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Replacement Times',
  },
  {
    keyword: 'optimalReplaceTime_1',
    valueType: 2,
    fieldName: 'optimalReplaceTime_1',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Optimal Replace Time',
  },
  {
    keyword: 'optimalUtility_1',
    valueType: 2,
    fieldName: 'optimalUtility_1',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Optimal Utility ($)',
  },
  {
    keyword: 'optimalRecoat_2',
    valueType: 0,
    fieldName: 'optimalRecoat_2',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Recoat?',
  },
  {
    keyword: 'optimalRecoatTime_2',
    valueType: 2,
    fieldName: 'optimalRecoatTime_2',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Optimal Recoat Time',
  },
  {
    keyword: 'optimalReplaceTime_2',
    valueType: 2,
    fieldName: 'optimalReplaceTime_2',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Optimal Replace Time',
  },
  {
    keyword: 'optimalUtility_2',
    valueType: 2,
    fieldName: 'optimalUtility_2',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Optimal Utility ($)',
  },
  {
    keyword: 'optimalRejacket_3',
    valueType: 0,
    fieldName: 'optimalRejacket_3',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Rejacket?',
  },
  {
    keyword: 'optimalRejacketTime_3',
    valueType: 2,
    fieldName: 'optimalRejacketTime_3',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Optimal Rejacket Time',
  },
  {
    keyword: 'optimalReplaceTime_3',
    valueType: 2,
    fieldName: 'optimalReplaceTime_3',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Optimal Replace Time',
  },
  {
    keyword: 'optimalUtility_3',
    valueType: 2,
    fieldName: 'optimalUtility_3',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Optimal Utility ($)',
  },
  {
    keyword: 'optimalReseal_4',
    valueType: 2,
    fieldName: 'optimalReseal_4',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Reseal?',
  },
  {
    keyword: 'optimalResealTime_4',
    valueType: 2,
    fieldName: 'optimalResealTime_4',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Optimal Reseal Time',
  },
  {
    keyword: 'optimalReplaceTime_4',
    valueType: 2,
    fieldName: 'optimalReplaceTime_4',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Optimal Replace Time',
  },
  {
    keyword: 'optimalUtility_4',
    valueType: 2,
    fieldName: 'optimalUtility_4',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Optimal Utility ($)',
  },
  {
    keyword: 'optimalRecoatAndRejacket_5',
    valueType: 0,
    fieldName: 'optimalRecoatAndRejacket_5',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Recoat and Rejacket?',
  },
  {
    keyword: 'optimalRecoatAndRejacketTime_5',
    valueType: 2,
    fieldName: 'optimalRecoatAndRejacketTime_5',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Optimal Recoat and Rejacket Time',
  },
  {
    keyword: 'optimalReplaceTime_5',
    valueType: 2,
    fieldName: 'optimalReplaceTime_5',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Optimal Replace Time',
  },
  {
    keyword: 'optimalUtility_5',
    valueType: 2,
    fieldName: 'optimalUtility_5',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Optimal Utility ($)',
  },
  {
    keyword: 'optimalRecoatAndReseal_6',
    valueType: 0,
    fieldName: 'optimalRecoatAndReseal_6',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Recoat and Reseal?',
  },
  {
    keyword: 'optimalRecoatAndResealTime_6',
    valueType: 2,
    fieldName: 'optimalRecoatAndResealTime_6',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Optimal Recoat and Reseal Time',
  },
  {
    keyword: 'optimalReplaceTime_6',
    valueType: 2,
    fieldName: 'optimalReplaceTime_6',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Optimal Replace Time',
  },
  {
    keyword: 'optimalUtility_6',
    valueType: 2,
    fieldName: 'optimalUtility_6',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Optimal Utility ($)',
  },
  {
    keyword: 'api581PercentProbFailureYes',
    valueType: 2,
    fieldName: 'api581PercentProbFailureYes',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'API581 Percent Probanility of Failure',
  },
  {
    keyword: 'api581FailureTimeEV',
    valueType: 2,
    fieldName: 'api581FailureTimeEV',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'API581 Failure Time Mean',
  },
  {
    keyword: 'api581FailureTimeSD',
    valueType: 2,
    fieldName: 'api581FailureTimeSD',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'API581 Failure Time SD',
  },
  {
    keyword: 'bpFailureTimeEV',
    valueType: 2,
    fieldName: 'bpFailureTimeEV',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'BP Failure Time Mean',
  },
  {
    keyword: 'bpFailureTimeSD',
    valueType: 2,
    fieldName: 'bpFailureTimeSD',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'BP Failure Time SD',
  },
  {
    keyword: 'ineosFailureTimeEV',
    valueType: 2,
    fieldName: 'ineosFailureTimeEV',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Ineos Failure Time Mean',
  },
  {
    keyword: 'ineosFailureTimeSD',
    valueType: 2,
    fieldName: 'ineosFailureTimeSD',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Ineos Failure Time SD',
  },
  {
    keyword: 'mcvayFailureTimeEV',
    valueType: 2,
    fieldName: 'mcvayFailureTimeEV',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'McVay Failure Time Mean',
  },
  {
    keyword: 'mcvayFailureTimeSD',
    valueType: 2,
    fieldName: 'mcvayFailureTimeSD',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'McVay Failure Time SD',
  },
  {
    keyword: 'api583FailureTimeEV',
    valueType: 2,
    fieldName: 'api583FailureTimeEV',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'API583 Failure Time Mean',
  },
  {
    keyword: 'api583FailureTimeSD',
    valueType: 2,
    fieldName: 'api583FailureTimeSD',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'API583 Failure Time SD',
  },
  {
    keyword: 'euroMethodPrimaryScoreEV',
    valueType: 2,
    fieldName: 'euroMethodPrimaryScoreEV',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Euro Primary Score EV',
  },
  {
    keyword: 'euroMethodPrimaryScoreSD',
    valueType: 2,
    fieldName: 'euroMethodPrimaryScoreSD',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Euro Primary Score SD',
  },
  {
    keyword: 'coatingFirstFailureTimeOutputEV',
    valueType: 2,
    fieldName: 'coatingFirstFailureTimeOutputEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'The',
  },
  {
    keyword: 'coatingFirstFailureTimeOutputSD',
    valueType: 2,
    fieldName: 'coatingFirstFailureTimeOutputSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'The',
  },
  {
    keyword: 'failureTimeMissingJacketingOutputEV',
    valueType: 2,
    fieldName: 'failureTimeMissingJacketingOutputEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'The',
  },
  {
    keyword: 'failureTimeMissingJacketingOutputSD',
    valueType: 2,
    fieldName: 'failureTimeMissingJacketingOutputSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'The',
  },
  {
    keyword: 'sealantFailureTimeOutputEV',
    valueType: 2,
    fieldName: 'sealantFailureTimeOutputEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'The',
  },
  {
    keyword: 'vaporBarrierFailureTimeMissingOutputEV',
    valueType: 2,
    fieldName: 'vaporBarrierFailureTimeMissingOutputEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'The',
  },
  {
    keyword: 'vaporBarrierFailureTimeMissingOutputSD',
    valueType: 2,
    fieldName: 'vaporBarrierFailureTimeMissingOutputSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'The',
  },

  {
    keyword: 'vaporBarrierFailureTimeOutputEV',
    valueType: 2,
    fieldName: 'vaporBarrierFailureTimeOutputEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'The',
  },
  {
    keyword: 'vaporBarrierFailureTimeOutputSD',
    valueType: 2,
    fieldName: 'vaporBarrierFailureTimeOutputSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'The',
  },
  {
    keyword: 'condensationFailureTimeEV',
    valueType: 2,
    fieldName: 'condensationFailureTimeEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'The',
  },
  {
    keyword: 'pofJacketJointNowPOF',
    valueType: 2,
    fieldName: 'pofJacketJointNowPOF',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'The',
  },
  {
    keyword: 'failureMissingVaporBarrierPOF',
    valueType: 2,
    fieldName: 'failureMissingVaporBarrierPOF',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'The',
  },
  {
    keyword: 'failureCondensationSeamsPOF',
    valueType: 2,
    fieldName: 'failureCondensationSeamsPOF',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'The',
  },
  {
    keyword: 'condensationFailureTimeSD',
    valueType: 2,
    fieldName: 'condensationFailureTimeSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'The',
  },
  {
    keyword: 'sealantFailureTimeOutputSD',
    valueType: 2,
    fieldName: 'sealantFailureTimeOutputSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'The',
  },
  {
    keyword: 'corrosionFailureTimeOutputEV',
    valueType: 2,
    fieldName: 'corrosionFailureTimeOutputEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'The',
  },
  {
    keyword: 'corrosionFailureTimeOutputSD',
    valueType: 2,
    fieldName: 'corrosionFailureTimeOutputSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'The',
  },
  {
    keyword: 'coatingDamageRateOutputEV',
    valueType: 2,
    fieldName: 'coatingDamageRateOutputEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%/yr',
      Metric: '%/yr',
    },
    type: 'output',
    label: 'The',
  },
  {
    keyword: 'coatingDamageRateOutputSD',
    valueType: 2,
    fieldName: 'coatingDamageRateOutputSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%/yr',
      Metric: '%/yr',
    },
    type: 'output',
    label: 'The',
  },
  {
    keyword: 'sealantDamageRateOutputEV',
    valueType: 2,
    fieldName: 'sealantDamageRateOutputEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%/yr',
      Metric: '%/yr',
    },
    type: 'output',
    label: 'The',
  },
  {
    keyword: 'sealantDamageRateOutputSD',
    valueType: 2,
    fieldName: 'sealantDamageRateOutputSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%/yr',
      Metric: '%/yr',
    },
    type: 'output',
    label: 'The',
  },
  {
    keyword: 'corrosionJacketDamageRateOutputEV',
    valueType: 2,
    fieldName: 'corrosionJacketDamageRateOutputEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%/yr',
      Metric: '%/yr',
    },
    type: 'output',
    label: 'The',
  },
  {
    keyword: 'corrosionJacketDamageRateOutputSD',
    valueType: 2,
    fieldName: 'corrosionJacketDamageRateOutputSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%/yr',
      Metric: '%/yr',
    },
    type: 'output',
    label: 'The',
  },
  {
    keyword: 'failureCoating1POF',
    valueType: 2,
    fieldName: 'failureCoating1POF',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Coating POF at Component Age',
  },
  {
    keyword: 'failureCoating2POF',
    valueType: 2,
    fieldName: 'failureCoating2POF',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Coating POF at Component Age',
  },
  {
    keyword: 'failureCoating3POF',
    valueType: 2,
    fieldName: 'failureCoating3POF',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Coating POF at Component Age',
  },
  {
    keyword: 'failureJacketingMissingPOF',
    valueType: 2,
    fieldName: 'failureJacketingMissingPOF',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Missing Jacketing POF at Component Age',
  },
  {
    keyword: 'failureJacketingSealantPOF',
    valueType: 2,
    fieldName: 'failureJacketingSealantPOF',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Jacketing Sealant POF at Component Age',
  },
  {
    keyword: 'failureJacketingCorrosionPOF',
    valueType: 2,
    fieldName: 'failureJacketingCorrosionPOF',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Jacketing Corrosion POF at Component Age',
  },
  {
    keyword: 'failureMissingPOF',
    valueType: 2,
    fieldName: 'failureMissingPOF',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Component (Missing) POF at Component Age',
  },
  {
    keyword: 'finalComponentPOF',
    valueType: 2,
    fieldName: 'finalComponentPOF',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Component (Final) POF at Component Age',
  },
  {
    keyword: 'failureSealantPOF',
    valueType: 2,
    fieldName: 'failureSealantPOF',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Component (Sealant) POF at Component Age',
  },
  {
    keyword: 'failureCorrosionPOF',
    valueType: 2,
    fieldName: 'failureCorrosionPOF',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Component (Corrosion) POF at Component Age',
  },
  {
    keyword: 'minActualThicknessValuesEV',
    valueType: 2,
    fieldName: 'minActualThicknessValuesEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'output',
    label: 'Actual Thickness at Component Age Mean',
  },
  {
    keyword: 'minActualThicknessValuesSD',
    valueType: 2,
    fieldName: 'minActualThicknessValuesSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'output',
    label: 'Actual Thickness at Component Age SD',
  },
  {
    keyword: 'actualThicknessAtThatTimeEVa',
    valueType: 2,
    fieldName: 'actualThicknessAtThatTimeEVa',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'output',
    label: 'Actual Thickness at Component Age Mean',
  },
  {
    keyword: 'actualThicknessAtThatTimeSDa',
    valueType: 2,
    fieldName: 'actualThicknessAtThatTimeSDa',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'output',
    label: 'Actual Thickness at Component Age SD',
  },
  {
    keyword: 'actualThicknessAtThatTimeEVb',
    valueType: 2,
    fieldName: 'actualThicknessAtThatTimeEVb',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'output',
    label: 'Actual Thickness at Component Age Mean',
  },
  {
    keyword: 'actualThicknessAtThatTimeSDb',
    valueType: 2,
    fieldName: 'actualThicknessAtThatTimeSDb',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'output',
    label: 'Actual Thickness at Component Age SD',
  },
  {
    keyword: 'actualThicknessAtThatTimeEVc',
    valueType: 2,
    fieldName: 'actualThicknessAtThatTimeEVc',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'output',
    label: 'Actual Thickness at Component Age Mean',
  },
  {
    keyword: 'actualThicknessAtThatTimeSDc',
    valueType: 2,
    fieldName: 'actualThicknessAtThatTimeSDc',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'output',
    label: 'Actual Thickness at Component Age SD',
  },
  {
    keyword: 'startingThicknessStatesPrior',
    valueType: 0,
    fieldName: 'startingThicknessStatesPrior',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'startingThicknessStates',
    valueType: 0,
    fieldName: 'startingThicknessStates',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'coatingFailureTimeStatesPrior',
    valueType: 0,
    fieldName: 'coatingFailureTimeStatesPrior',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'coatingFailureTimeStates',
    valueType: 0,
    fieldName: 'coatingFailureTimeStates',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'jacketingFailureTimeStatesPrior',
    valueType: 0,
    fieldName: 'jacketingFailureTimeStatesPrior',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'jacketingFailureTimeStates',
    valueType: 0,
    fieldName: 'jacketingFailureTimeStates',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'initiationTimeStatesPrior',
    valueType: 0,
    fieldName: 'initiationTimeStatesPrior',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'initiationTimeStates',
    valueType: 0,
    fieldName: 'initiationTimeStates',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'damageRateStatesPrior',
    valueType: 0,
    fieldName: 'damageRateStatesPrior',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'damageRateStates',
    valueType: 0,
    fieldName: 'damageRateStates',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'failureTimeStatesPrior',
    valueType: 0,
    fieldName: 'failureTimeStatesPrior',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'failureTimeStates',
    valueType: 0,
    fieldName: 'failureTimeStates',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'api581MethodAdjustedCRStates',
    valueType: 0,
    fieldName: 'api581MethodAdjustedCRStates',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'api581MethodFailureTimeStates',
    valueType: 0,
    fieldName: 'api581MethodFailureTimeStates',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'bpMethodPrimaryScoreStates',
    valueType: 0,
    fieldName: 'bpMethodPrimaryScoreStates',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'ineosMethodPrimaryScoreStates',
    valueType: 0,
    fieldName: 'ineosMethodPrimaryScoreStates',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'McVayMethodPrimaryScoreStates',
    valueType: 0,
    fieldName: 'McVayMethodPrimaryScoreStates',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'api583TotalScoreStates',
    valueType: 0,
    fieldName: 'api583TotalScoreStates',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'euroMethodPrimaryScoreStates',
    valueType: 0,
    fieldName: 'euroMethodPrimaryScoreStates',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'sealantDamageRateStatesOutput',
    valueType: 0,
    fieldName: 'sealantDamageRateStatesOutput',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'failureTimeMissingJacketingStatesOutput',
    valueType: 0,
    fieldName: 'failureTimeMissingJacketingStatesOutput',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'corrosionFailureTimeStatesOutput',
    valueType: 0,
    fieldName: 'corrosionFailureTimeStatesOutput',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'corrosionJacketDamageRateStatesOutput',
    valueType: 0,
    fieldName: 'corrosionJacketDamageRateStatesOutput',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'coatingFirstFailureTimeStatesOutput',
    valueType: 0,
    fieldName: 'coatingFirstFailureTimeStatesOutput',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'coatingDamageRateStatesOutput',
    valueType: 0,
    fieldName: 'coatingDamageRateStatesOutput',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'failureTimeStates',
    valueType: 0,
    fieldName: 'failureTimeStates',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'condensationFailureTimeStates',
    valueType: 0,
    fieldName: 'condensationFailureTimeStates',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'sealantFailureTimeStatesOutput',
    valueType: 0,
    fieldName: 'sealantFailureTimeStatesOutput',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'missingJacketingComponentFailureTimeStateLevelsReturn',
    valueType: 0,
    fieldName: 'missingJacketingComponentFailureTimeStateLevelsReturn',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'condensationJacketingComponentFailureTimeStateLevelsReturn',
    valueType: 0,
    fieldName: 'condensationJacketingComponentFailureTimeStateLevelsReturn',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'missingVaporBarrierComponentFailureTimeStateLevelsReturn',
    valueType: 0,
    fieldName: 'missingVaporBarrierComponentFailureTimeStateLevelsReturn',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'sealantJacketingComponentFailureTimeStateLevelsReturn',
    valueType: 0,
    fieldName: 'sealantJacketingComponentFailureTimeStateLevelsReturn',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'corrosionComponentFailureTimeLevelsReturn',
    valueType: 0,
    fieldName: 'corrosionComponentFailureTimeLevelsReturn',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'actualThicknessStates',
    valueType: 0,
    fieldName: 'actualThicknessStates',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'labelTitleOrg',
    valueType: 0,
    fieldName: 'labelTitleOrg',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'labelXOrg',
    valueType: 0,
    fieldName: 'labelXOrg',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'labelYOrg',
    valueType: 0,
    fieldName: 'labelYOrg',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'actualThicknessStates',
    valueType: 0,
    fieldName: 'actualThicknessStates',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'labelTitle',
    valueType: 0,
    fieldName: 'labelTitle',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'labelX',
    valueType: 0,
    fieldName: 'labelX',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'labelY',
    valueType: 0,
    fieldName: 'labelY',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'labelTitleReplacement',
    valueType: 0,
    fieldName: 'labelTitleReplacement',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'inspections',
    valueType: 2,
    fieldName: 'inspections',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'labelXReplacement',
    valueType: 0,
    fieldName: 'labelXReplacement',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'labelYReplacement',
    valueType: 0,
    fieldName: 'labelYReplacement',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutDewPoint',
    valueType: 0,
    fieldName: 'poppedJsonOutDewPoint',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutVaporBarrierFailureTime',
    valueType: 0,
    fieldName: 'poppedJsonOutVaporBarrierFailureTime',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutWetCorrosionRate',
    valueType: 0,
    fieldName: 'poppedJsonOutWetCorrosionRate',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutExternalFraction',
    valueType: 0,
    fieldName: 'poppedJsonOutExternalFraction',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutInternalExposureFraction',
    valueType: 0,
    fieldName: 'poppedJsonOutInternalExposureFraction',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutMaxEffectiveCorrosionRate',
    valueType: 0,
    fieldName: 'poppedJsonOutMaxEffectiveCorrosionRate',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutJacketingMissingFailureTime',
    valueType: 0,
    fieldName: 'poppedJsonOutJacketingMissingFailureTime',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutJacketingSealantFailureTime',
    valueType: 0,
    fieldName: 'poppedJsonOutJacketingSealantFailureTime',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutJacketingDesignFailureTime',
    valueType: 0,
    fieldName: 'poppedJsonOutJacketingDesignFailureTime',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutJacketingCorrosionFailureTime',
    valueType: 0,
    fieldName: 'poppedJsonOutJacketingCorrosionFailureTime',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutDewPointPrior',
    valueType: 0,
    fieldName: 'poppedJsonOutDewPointPrior',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutWetCorrosionRatePrior',
    valueType: 0,
    fieldName: 'poppedJsonOutWetCorrosionRatePrior',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutExternalFractionPrior',
    valueType: 0,
    fieldName: 'poppedJsonOutExternalFractionPrior',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutInternalExposureFractionPrior',
    valueType: 0,
    fieldName: 'poppedJsonOutInternalExposureFractionPrior',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutMaxEffectiveCorrosionRatePrior',
    valueType: 0,
    fieldName: 'poppedJsonOutMaxEffectiveCorrosionRatePrior',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutJacketingMissingFailureTimePrior',
    valueType: 0,
    fieldName: 'poppedJsonOutJacketingMissingFailureTimePrior',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutJacketingSealantFailureTimePrior',
    valueType: 0,
    fieldName: 'poppedJsonOutJacketingSealantFailureTimePrior',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutJacketingDesignFailureTimePrior',
    valueType: 0,
    fieldName: 'poppedJsonOutJacketingDesignFailureTimePrior',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutJacketingCorrosionFailureTimePrior',
    valueType: 0,
    fieldName: 'poppedJsonOutJacketingCorrosionFailureTimePrior',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutCoatingFailureTimePrior',
    valueType: 0,
    fieldName: 'poppedJsonOutCoatingFailureTimePrior',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutComponentFailureTimePrior',
    valueType: 0,
    fieldName: 'poppedJsonOutComponentFailureTimePrior',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
]
